import styled from "styled-components";

export const JoinGroupContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: sticky;
  top: 0;
  background-color: white; /* Adicione um fundo para que ele cubra os elementos atrás dele */
  z-index: 1000; /* Ajuste o z-index conforme necessário para garantir que ele fique acima de outros elementos */
  padding: 10px;
`;

export const JoinGroupInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px 0 0 8px;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #6200ee;
    box-shadow: 0 0 5px rgba(98, 0, 238, 0.5);
  }
`;

export const JoinGroupButton = styled.button`
  padding: 10px 20px;
  background-color: #a465ff;
  color: white;
  border: none;
  border-radius: 0 8px 8px 0;
  font-size: 16px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #3700b3;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(98, 0, 238, 0.5);
  }
`;

export const GroupCard = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  position: relative; /* To position the badge correctly */
  cursor: pointer;
`;

export const GroupImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
`;

export const GroupDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GroupName = styled.h2`
  margin-bottom: 10px;
`;

export const GroupPin = styled.p`
  color: #6200ee;
  font-weight: bold;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

export const StateBadge = styled.div<{ state: string }>`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${(props) =>
    props.state === "CLOSED" ? "#ffcccc" : "#ccffcc"};
  color: #333;
  padding: 5px 10px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-weight: bold;
`;
