import React from "react";
import styled from "styled-components";

interface BadgeProps {
  color: string;
}

const BadgeWrapper = styled.div<BadgeProps>`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  border: 2px solid ${(props) => props.color};
`;

const Badge: React.FC<BadgeProps> = ({ color }) => {
  return <BadgeWrapper color={color} />;
};

export default Badge;
