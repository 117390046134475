import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import {
  getOrdersByDrawIdAndPromoterId,
  validateOrder,
  isOverLimit,
} from "../services/api";
import { OrderDTO } from "../types";
import {
  OrderCard,
  OrderHeader,
  OrderPin,
  OrderState,
  TicketCard,
  TicketUserName,
  TicketNumberList,
  TicketNumberBall,
  ValueDetails,
  Modal,
  ModalContent,
  ModalButton,
  ModalOverlay,
  UserName,
  PIN,
  ContactManagerContainer,
  ContactManagerLink,
} from "../styles/OrderPromoterStyles";
import { useTranslation } from "react-i18next";
import NavBar from "../components/NavBar";
import {
  Container,
  BackButton,
  PageTitle,
  CancelButton,
  ErrorMessage,
} from "../styles/GlobalStyles";
import { FaWhatsapp } from "react-icons/fa";
import LoadingOverlay from "../components/LoadingOverlay";

const OrdersPromoterPage: React.FC = () => {
  const { user } = useAuth();
  const { drawId } = useParams<{ drawId: string }>();
  const { t } = useTranslation();
  const [orders, setOrders] = useState<OrderDTO[]>([]);
  const navigate = useNavigate();
  const [selectedOrder, setSelectedOrder] = useState<OrderDTO | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrders = async () => {
      if (user?.id) {
        try {
          setIsLoading(true);
          const response = await getOrdersByDrawIdAndPromoterId(
            Number(drawId),
            user.id
          );
          setOrders(response);
        } catch (error) {
          console.error("Error fetching orders:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchOrders();
  }, [user]);

  const handleValidate = async () => {
    if (selectedOrder && user!.id !== undefined) {
      try {
        setIsLoading(true);
        const overLimit = await isOverLimit(
          selectedOrder.draw.groupId,
          user!.id,
          selectedOrder.total
        );

        if (overLimit) {
          setError(t("order.overLimitError"));
        } else {
          const validatedOrderResponse = await validateOrder(selectedOrder.pin);
          setOrders((prevOrders) =>
            prevOrders.map((o) =>
              o.pin === selectedOrder.pin
                ? { ...o, ...validatedOrderResponse }
                : o
            )
          );
          handleCloseModal();
        }
      } catch (error) {
        console.error("Error validating order:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
  };

  const handleUserClick = (order: OrderDTO) => {
    if (
      (user?.id === order?.promoter?.id || order.draw.managerId === user?.id) &&
      order.state === "PENDING" &&
      order.draw.state === "INITIATED"
    ) {
      setSelectedOrder(order);
      setShowModal(true);
    }
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay />}
      <BackButton onClick={() => navigate("/draw/" + drawId)}>{"<"}</BackButton>
      <PageTitle>{t("order.titlePromoter")}</PageTitle>

      {orders
        .sort((a, b) => {
          const stateOrder: { [key: string]: number } = {
            PENDING: 1,
            VALIDATED: 2,
            CANCELLED: 3,
          };

          // Primeiro compara os estados
          if (stateOrder[a.state] !== stateOrder[b.state]) {
            return stateOrder[a.state] - stateOrder[b.state];
          }

          // Se os estados forem iguais, ordena pelo id de forma decrescente
          return b.id - a.id;
        })
        .map((order) => (
          <OrderCard key={order.id} onClick={() => handleUserClick(order)}>
            <OrderHeader>
              <OrderPin>
                {t("order.pin")}: {order.pin}
              </OrderPin>
              <OrderState>
                {t(`orderState.${order.state.toLowerCase()}`)}
              </OrderState>
            </OrderHeader>
            <div>
              <TicketUserName>
                {t("order.user")}: {order.user.name}
              </TicketUserName>
              {order.userPhone && (
                <ContactManagerContainer>
                  <ContactManagerLink
                    href={`https://wa.me/${order.userPhone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp size={20} />
                    {t("order.contactUser")}
                  </ContactManagerLink>
                </ContactManagerContainer>
              )}
              {order.draw.managerId === user?.id && (
                <TicketUserName>
                  {t("order.promoter")}: {order.promoter.name}
                </TicketUserName>
              )}
              {order.tickets.map((ticket) => (
                <TicketCard key={ticket.id}>
                  <TicketNumberList>
                    {ticket.numbers.map((number) => (
                      <TicketNumberBall
                        key={number.id}
                        isDrawn={number.isDrawn}
                      >
                        {number.number}
                      </TicketNumberBall>
                    ))}
                  </TicketNumberList>
                </TicketCard>
              ))}
            </div>
            <ValueDetails>
              <span>{`${t("order.total")}: $${order.total}`}</span>
            </ValueDetails>
          </OrderCard>
        ))}
      {showModal && selectedOrder && (
        <>
          <ModalOverlay />
          <Modal>
            <ModalContent>
              {error ? (
                <ErrorMessage>{error}</ErrorMessage>
              ) : (
                <>
                  <UserName>
                    {t("order.user")}: {selectedOrder.user.name}
                  </UserName>
                  <PIN>
                    {t("order.pin")}: {selectedOrder.pin}
                  </PIN>
                  <ModalButton onClick={handleValidate}>
                    {t("order.validate")}
                  </ModalButton>
                </>
              )}
              <br />
              <CancelButton onClick={handleCloseModal}>
                {t("order.cancel")}
              </CancelButton>
            </ModalContent>
          </Modal>
        </>
      )}
      <NavBar />
    </Container>
  );
};

export default OrdersPromoterPage;
