import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import ChatMessage from "../components/ChatMessage";
import ChatInput from "../components/ChatInput";

const ChatPage: React.FC = () => {
  const [messages, setMessages] = useState([
    { sender: "User1", message: "Hello!", timestamp: "10:00 AM" },
    { sender: "User2", message: "Hi there!", timestamp: "10:01 AM" },
  ]);

  const handleSend = (message: string) => {
    const newMessage = {
      sender: "You",
      message,
      timestamp: new Date().toLocaleTimeString(),
    };
    setMessages([...messages, newMessage]);
  };

  return <></>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); // Ajuste a altura conforme necessário
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #f5f5f5;
`;

export default ChatPage;
