import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const PasswordInput = styled(Input)<{ error?: boolean }>`
  border-color: ${(props) => (props.error ? "red" : "#ddd")};
`;

export const Button = styled.button`
  padding: 10px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #3700b3;
  }
`;

export const RegisterLink = styled.a`
  text-align: center;
  color: #6200ee;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #3700b3;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

export const InfoMessage = styled.p`
  color: blue;
  text-align: center;
  margin-bottom: 10px;
`;

export const PhoneContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
`;

export const CountryCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f5f5f5;
  margin-right: 10px;
  font-size: 16px;
  height: 42px; /* Matches the height of Input elements */
  box-sizing: border-box;
`;

export const RegionalCode = styled(Input)`
  width: 60px;
  margin-right: 10px;
  box-sizing: border-box;
`;

export const PhoneNumber = styled(Input)`
  flex: 1;
  box-sizing: border-box;
`;

export const NoteContainer = styled.div`
  margin-top: 20px;
  text-align: left;
  font-size: 14px;
  color: #40e0d0;
`;
