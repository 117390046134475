import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getDrawById,
  getLotteryResultsByDrawId,
  getUserById,
  changeDrawName,
} from "../services/api";
import { DrawDTO, LotteryResultDTO, TicketDTO, UserDTO } from "../types";
import {
  DrawCard,
  NumberBall,
  TicketCard,
  UserName,
  NumberList,
  ModalButton,
  Modal,
  ModalContent,
  ModalOverlay,
  DrawDate,
  DrawDateContainer,
  NumberBallLottery,
  PDFIcon,
  SearchContainer,
  SearchInput,
  EditIcon,
  NameContainer,
  EditModal,
  EditModalContent,
  EditModalOverlay,
  EditModalInput,
  EditModalButtonContainer,
  EditModalConfirmButton,
  EditModalCancelButton,
  DrawDateSub,
} from "../styles/DrawStyles";
import {
  Container,
  BackButton,
  PageTitle,
  PageSubtitle,
  SettingsButton,
  CancelButton,
  FloatingButton,
} from "../styles/GlobalStyles";
import { useTranslation } from "react-i18next";
import NavBar from "../components/NavBar";
import { useAuth } from "../contexts/AuthContext";
import { startDraw, reOpenDraw } from "../services/api";
import { FaPlus } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import Badge from "../components/Badge";
import LoadingOverlay from "../components/LoadingOverlay";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import "../styles/DrawStyles.css";

const DrawPage: React.FC = () => {
  const { drawId } = useParams<{ drawId: string }>();
  const navigate = useNavigate();
  const [draw, setDraw] = useState<DrawDTO | null>(null);
  const [processedData, setProcessedData] = useState<any>(null);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [downloading, setDownloading] = useState<Boolean>(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newDrawName, setNewDrawName] = useState<string>("");

  const availableColors = [
    "#20B2AA",
    "#FF6347",
    "#FFD700",
    "#6A5ACD",
    "#6B8E23",
    "#9ACD32",
    "#708090",
    "#D2B48C",
    "#8A2BE2",
    "#32CD32",
    "#DA70D6",
    "#CD853F",
    "#1E90FF",
    "#8FBC8F",
    "#A0522D",
    "#FF4500",
    "#5F9EA0",
    "#4682B4",
    "#DEB887",
    "#00CED1",
    "#556B2F",
    "#BDB76B",
    "#8B4513",
    "#EEE8AA",
    "#F4A460",
    "#9932CC",
    "#808000",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!drawId) return;

        const [drawResponse, lotteryResultsResponse] = await Promise.all([
          getDrawById(Number(drawId)),
          getLotteryResultsByDrawId(Number(drawId)),
        ]);

        const sortedLotteryResults = lotteryResultsResponse.sort(
          (a: LotteryResultDTO, b: LotteryResultDTO) => {
            const dateA = new Date(a.drawDate);
            const dateB = new Date(b.drawDate);
            return dateB.getTime() - dateA.getTime();
          }
        );

        const colorMap: { [key: number]: string } = {};
        sortedLotteryResults.forEach(
          (result: LotteryResultDTO, index: number) => {
            colorMap[result.resultId] =
              availableColors[index % availableColors.length];
          }
        );

        const processedTickets = drawResponse.tickets
          .filter(
            (ticket: TicketDTO) =>
              ticket.state !== "PENDING" && ticket.state !== "CLOSED"
          )
          .sort((a: TicketDTO, b: TicketDTO) => {
            const aDrawnCount = a.numbers.filter(
              (number) => number.isDrawn
            ).length;
            const bDrawnCount = b.numbers.filter(
              (number) => number.isDrawn
            ).length;
            return bDrawnCount - aDrawnCount;
          })
          .map((ticket: TicketDTO) => ({
            ...ticket,
            username: ticket.userName,
            sortedNumbers: ticket.numbers.sort((a, b) => a.number - b.number),
          }));

        setDraw(drawResponse);
        setProcessedData({
          sortedLotteryResults,
          colorMap,
          processedTickets,
        });
      } catch (error) {
        console.error("Error fetching draw details:", error);
      }
    };

    fetchData();
  }, [drawId]);

  const isManager = user?.id === draw?.managerId;

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUserClick = () => {
    if (draw?.state === "INITIATED" || draw?.state === "IN_PROGRESS") {
      setShowModal(true);
    }
  };

  const handleInProgress = async () => {
    if (draw) {
      try {
        await startDraw(draw.id);
        handleCloseModal();
      } catch (error) {
        console.error("Error promoting user:", error);
      }
    }
  };

  const handleReOpenDraw = async () => {
    if (draw) {
      try {
        await reOpenDraw(draw.id);
        handleCloseModal();
      } catch (error) {
        console.error("Error promoting user:", error);
      }
    }
  };

  const handleCreateOrderClick = () => {
    navigate(`/draw/${drawId}/${user?.id}/create`);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById("pdf-content");
    if (!input) return;

    setTimeout(() => {
      setDownloading(true);

      const searchContainer = document.querySelector(".search-container");
      const floatingButton = document.querySelector(".floating-button");

      if (searchContainer) searchContainer.classList.add("hidden-for-pdf");
      if (floatingButton) floatingButton.classList.add("hidden-for-pdf");

      // Increase the scale for better quality, but be cautious of memory usage
      html2canvas(input, { scale: 1 })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg", 0.8); // Use JPEG and adjust quality
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = pdf.internal.pageSize.getWidth() / 3;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const pageHeight = pdf.internal.pageSize.getHeight();
          let heightLeft = imgHeight;
          let position = 10;

          // Create blank canvas
          const blankCanvas = document.createElement("canvas");
          blankCanvas.width = canvas.width;
          blankCanvas.height = canvas.height;
          const blankCtx = blankCanvas.getContext("2d");
          if (blankCtx) {
            blankCtx.fillStyle = "white";
            blankCtx.fillRect(0, 0, blankCanvas.width, blankCanvas.height);
          }
          const blankImgData = blankCanvas.toDataURL("image/jpeg", 0.8); // Use JPEG and adjust quality

          while (heightLeft >= 0) {
            pdf.addImage(
              blankImgData,
              "JPEG",
              0,
              position,
              imgWidth,
              imgHeight
            );
            pdf.addImage(
              imgData,
              "JPEG",
              imgWidth,
              position,
              imgWidth,
              imgHeight
            );
            pdf.addImage(
              blankImgData,
              "JPEG",
              2 * imgWidth,
              position,
              imgWidth,
              imgHeight
            );
            heightLeft -= pageHeight;
            position -= pageHeight;

            if (heightLeft > 0) {
              pdf.addPage();
            }
          }

          const fileName = `draw_${drawId}.pdf`;

          if (
            /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
              navigator.userAgent.toLowerCase()
            )
          ) {
            saveAs(pdf.output("blob"), fileName);
          } else {
            pdf.save(fileName);
          }

          if (searchContainer)
            searchContainer.classList.remove("hidden-for-pdf");
          if (floatingButton) floatingButton.classList.remove("hidden-for-pdf");

          setDownloading(false);
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
          setDownloading(false);
        });
    }, 100);
  };

  if (!processedData || downloading) {
    return <LoadingOverlay />;
  }

  const filteredTickets = processedData.processedTickets.filter(
    (ticket: TicketDTO & { username: string }) =>
      ticket.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditClick = () => {
    setNewDrawName(draw?.name || "");
    setShowEditModal(true);
  };

  const handleEditConfirm = async () => {
    if (newDrawName.trim() === "") return;
    try {
      const updatedDraw = await changeDrawName({
        id: Number(drawId),
        name: newDrawName,
      });
      setDraw((prev) => (prev ? { ...prev, name: updatedDraw.name } : prev));
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating draw name:", error);
    }
  };

  const handleEditCancel = () => {
    setShowEditModal(false);
  };

  return (
    <Container>
      <BackButton onClick={() => navigate("/group/" + draw?.groupId)}>
        {"<"}
      </BackButton>
      {isManager && <SettingsButton onClick={() => handleUserClick()} />}
      <div id="pdf-content">
        <NameContainer>
          <h2 className="draw-name">{draw?.name}</h2>
          <div className="draw-id-container">
            <h2 className="draw-id">{`#${drawId}`}</h2>
            {isManager && <EditIcon onClick={handleEditClick} />}
          </div>
        </NameContainer>
        {processedData.sortedLotteryResults.length > 0 && (
          <DrawCard>
            {processedData.sortedLotteryResults.map(
              (result: LotteryResultDTO) => (
                <NumberList key={result.drawDate}>
                  <DrawDateContainer>
                    <DrawDate>#{result.resultId}</DrawDate>
                    <DrawDateSub>
                      {new Date(result.drawDate).toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                      })}
                    </DrawDateSub>
                  </DrawDateContainer>
                  {result.numbers
                    .sort((a, b) => a - b)
                    .map((number: number) => (
                      <NumberBallLottery
                        key={number}
                        isDrawn={true}
                        style={{
                          backgroundColor:
                            processedData.colorMap[result.resultId],
                        }}
                      >
                        {number}
                      </NumberBallLottery>
                    ))}
                </NumberList>
              )
            )}
          </DrawCard>
        )}
        <SearchContainer className="search-container">
          <SearchInput
            type="text"
            placeholder={t("draw.searchPlaceholder")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <PDFIcon onClick={handleDownloadPDF}>
            <FaFilePdf size={26} />
          </PDFIcon>
        </SearchContainer>
        {draw &&
          draw.tickets.length > 0 &&
          (draw.state === "IN_PROGRESS" ||
            draw.state === "PRIZED" ||
            draw.managerId === user?.id) && (
            <>
              {filteredTickets.map(
                (
                  ticket: TicketDTO & {
                    username: string;
                    sortedNumbers: typeof ticket.numbers;
                  }
                ) => (
                  <TicketCard
                    key={ticket.id}
                    isPrized={ticket.state === "PRIZED"}
                  >
                    <UserName isPrized={ticket.state === "PRIZED"}>
                      {ticket.username}
                    </UserName>
                    <NumberList>
                      {ticket.sortedNumbers.map((number) => (
                        <div style={{ position: "relative" }} key={number.id}>
                          <Badge
                            color={
                              number.resultId != null
                                ? processedData.colorMap[number.resultId]
                                : "transparent"
                            }
                          />
                          <NumberBall key={number.id} isDrawn={number.isDrawn}>
                            {number.number}
                          </NumberBall>
                        </div>
                      ))}
                    </NumberList>
                  </TicketCard>
                )
              )}
            </>
          )}
        {showModal && (
          <>
            <ModalOverlay />
            <Modal>
              <ModalContent>
                {draw?.state === "INITIATED" && (
                  <ModalButton onClick={handleInProgress}>
                    {t("draw.actionInProgress")}
                  </ModalButton>
                )}
                {draw?.state === "IN_PROGRESS" && (
                  <ModalButton onClick={handleReOpenDraw}>
                    {t("draw.actionReopenDraw")}
                  </ModalButton>
                )}
                <br />
                <CancelButton onClick={handleCloseModal}>
                  {t("order.cancel")}
                </CancelButton>
              </ModalContent>
            </Modal>
          </>
        )}
        {showEditModal && (
          <>
            <EditModalOverlay onClick={handleEditCancel} />
            <EditModal>
              <EditModalContent>
                <h2>{t("draw.editName")}</h2>
                <EditModalInput
                  value={newDrawName}
                  onChange={(e) => setNewDrawName(e.target.value)}
                />
                <EditModalButtonContainer>
                  <EditModalCancelButton onClick={handleEditCancel}>
                    {t("draw.cancel")}
                  </EditModalCancelButton>
                  <EditModalConfirmButton
                    onClick={handleEditConfirm}
                    disabled={newDrawName.trim() === ""}
                  >
                    {t("draw.confirm")}
                  </EditModalConfirmButton>
                </EditModalButtonContainer>
              </EditModalContent>
            </EditModal>
          </>
        )}
        {draw?.state === "INITIATED" && (
          <FloatingButton
            className="floating-button"
            onClick={handleCreateOrderClick}
          >
            <FaPlus />
          </FloatingButton>
        )}
      </div>
      <NavBar />
    </Container>
  );
};

export default DrawPage;
