import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import {
  getGroupsByUserId,
  joinGroup,
  getAllGroups,
  API_BASE_URL,
} from "../services/api";
import { GroupDTO, UserGroupDTO } from "../types";
import NavBar from "../components/NavBar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  GroupCard,
  GroupImage,
  GroupDetails,
  GroupName,
  GroupPin,
  JoinGroupContainer,
  JoinGroupInput,
  JoinGroupButton,
  ErrorMessage,
  StateBadge,
} from "../styles/HomeStyles";
import { Container, CreateIcon } from "../styles/GlobalStyles";
import LoadingOverlay from "../components/LoadingOverlay";

const HomePage: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [groups, setGroups] = useState<GroupDTO[]>([]);
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        setIsLoading(true);
        const userId = user?.id;
        if (userId) {
          if (user?.roles.some((role) => role.name === "ROLE_ADMIN")) {
            const response = await getAllGroups();
            setGroups(response);
          } else {
            const response = await getGroupsByUserId(userId);
            setGroups(response);
          }
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGroups();
  }, [user]);

  const handleGroupClick = (groupId: number) => {
    navigate(`/group/${groupId}`);
  };

  const handleCreateGroupClick = () => {
    navigate(`/group/create`);
  };

  const handleJoinGroup = async () => {
    try {
      if (pin) {
        const response: UserGroupDTO = await joinGroup(parseInt(pin), user!);
        if (response) {
          setGroups([...groups, response.group]);
          setPin("");
          setError("");
        } else {
          setError(t("group.notFound"));
        }
      }
    } catch (error) {
      setError(t("group.notFound"));
    }
  };

  const isManager = user?.roles.some(
    (role) => role.name === "ROLE_MANAGER" || role.name === "ROLE_ADMIN"
  );

  const compareGroups = (a: GroupDTO, b: GroupDTO) => {
    const stateOrder: { [key: string]: number } = {
      ACTIVE: 1,
      PENDING: 2,
      CLOSED: 3,
    };

    return (stateOrder[a.state] || 999) - (stateOrder[b.state] || 999);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay />}
      <JoinGroupContainer>
        <JoinGroupInput
          type="number"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          placeholder={t("group.enterPin")}
        />
        <JoinGroupButton onClick={handleJoinGroup}>
          {t("group.joinGroup")}
        </JoinGroupButton>
      </JoinGroupContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {groups.sort(compareGroups).map((group) => (
        <GroupCard key={group.id} onClick={() => handleGroupClick(group.id)}>
          {isManager && (
            <StateBadge state={group.state}>
              {t(`group.${group.state.toLowerCase()}`)}
            </StateBadge>
          )}
          <GroupImage src={`${API_BASE_URL}${group.image}`} alt={group.name} />
          <GroupDetails>
            <GroupName>{group.name}</GroupName>
            <GroupPin>{`${t("group.pin")}: ${group.pin}`}</GroupPin>
          </GroupDetails>
        </GroupCard>
      ))}
      {isManager && <CreateIcon onClick={() => handleCreateGroupClick()} />}
      <NavBar />
    </Container>
  );
};

export default HomePage;
