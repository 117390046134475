import styled from "styled-components";
import { IoIosCloseCircleOutline } from "react-icons/io";

export const NotificationCard = styled.div`
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  max-width: 100%;
  width: 400px;
`;

export const NotificationTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 8px;
`;

export const NotificationContent = styled.p`
  font-size: 16px;
  color: #333;
`;

export const ClearButton = styled(IoIosCloseCircleOutline)`
  color: #9790a1;
  font-size: 45px;
  cursor: pointer;
  margin-left: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
