import styled from "styled-components";

export const CardList = styled.div`
  padding-bottom: 60px;
`;

export const Card = styled.div`
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const CardTitle = styled.h2`
  margin: 0 0 10px;
`;

export const CardBody = styled.div`
  margin-bottom: 10px;
`;

export const CardFooter = styled.div`
  text-align: right;
`;

export const ExpandButton = styled.button`
  background-color: #6200ee;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #3700b3;
  }
`;

export const Button = styled.button`
  background-color: #6200ee;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #3700b3;
  }
`;

export const Input = styled.input`
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 60%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

export const InfoMessage = styled.p`
  color: blue;
  text-align: center;
  margin-bottom: 10px;
`;

export const MovementList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  max-height: 200px;
  overflow-y: auto;
`;

export const MovementItem = styled.li`
  padding: 5px 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

export const LoadMoreButton = styled.button`
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: #3700b3;
  }
`;
