import styled from "styled-components";
import { MdOutlineEdit } from "react-icons/md";

export const TicketCard = styled.div<{ isPrized: boolean }>`
  width: 100%;
  max-width: 400px;
  background-color: ${({ isPrized }) => (isPrized ? "#e0f7e9" : "white")}; /* Fundo verde claro */
  padding: 10px;
  margin: ${({ isPrized }) => (isPrized ? "50px 0" : "10px 0")};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: ${({ isPrized }) => (isPrized ? "1px solid #a5d6a7" : "none")}; /* Borda verde suave */
`;

export const UserName = styled.div<{ isPrized: boolean }>`
  position: absolute;
  top: -20px;
  left: -10px;
  background-color: ${({ isPrized }) => (isPrized ? "#66bb6a" : "#a79cb8")}; /* Verde mais escuro para 'PRIZED' */
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-weight: bold;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const ModalButton = styled.button<{ isDanger?: boolean }>`
  padding: 10px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #3700b3;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

export const DrawCard = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 0px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const NumberList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const DrawDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
`;

export const DrawDate = styled.span`
  font-size: 12px;
  color: #333;
`;

export const DrawDateSub = styled.span`
  font-size: 10px;
  color: #666;
`;

export const NumberBallLottery = styled.div<{ isDrawn: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10%; /* Ajuste dinâmico */
  height: auto;
  aspect-ratio: 1; /* Mantém o aspecto quadrado */
  margin: 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.isDrawn ? "#4caf50" : "#ddd")};
  color: white;
  font-weight: bold;
  box-shadow: ${(props) =>
    props.isDrawn
      ? "0 4px 8px rgba(0, 0, 0, 0.2), inset 0 -2px 5px rgba(0, 0, 0, 0.2)"
      : "0 4px 8px rgba(0, 0, 0, 0.1)"};
  text-shadow: ${(props) => (props.isDrawn ? "1px 1px 2px #000" : "none")};
  font-size: calc(1vw + 1vh); /* Tamanho da fonte ajustável */
`;

export const NumberBall = styled.div<{ isDrawn: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.isDrawn ? "#4caf50" : "#ddd")};
  color: white;
  font-weight: bold;
  box-shadow: ${(props) =>
    props.isDrawn
      ? "0 4px 8px rgba(0, 0, 0, 0.2), inset 0 -2px 5px rgba(0, 0, 0, 0.2)"
      : "0 4px 8px rgba(0, 0, 0, 0.1)"};
  text-shadow: ${(props) => (props.isDrawn ? "1px 1px 2px #000" : "none")};
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px; /* Cantos mais arredondados */
  font-size: 16px;
  margin-right: 10px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ddd" width="12" height="12" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zm-5.442 1.062a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"/></svg>');
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 40px;
  ::placeholder {
    color: #ddd; /* Cor cinza para o placeholder */
  }
`;

export const PDFIcon = styled.div`
  cursor: pointer;
  color: #d9534f;
  margin-top: 5px;
`;

export const EditIcon = styled(MdOutlineEdit)`
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 5px;
  color: #6200ee;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
  text-align: center;

  h2 {
    margin: 0;
  }

  .draw-name {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }

  .draw-id-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .draw-id {
    font-size: 1rem;
    color: #777;
  }
`;

export const EditModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const EditModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const EditModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const EditModalInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const EditModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

export const EditModalConfirmButton = styled.button<{ disabled: boolean }>`
  background-color: ${({ disabled }) => (disabled ? "#ddd" : "#6200ee")};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-left: 10px;
`;

export const EditModalCancelButton = styled.button`
  background-color: transparent;
  color: #6200ee;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  margin-left: 10px;
  margin-top: 10px;
`;
