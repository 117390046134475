import React from "react";
import { PixCode, PixContainer, PixType } from "../styles/PixStyles";
import { CopyIcon } from "../styles/GroupStyles";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface PixProps {
  pixCode: string;
  pixType: "CPF" | "PHONE"; // Use the same types as your backend enum
}

const Pix: React.FC<PixProps> = ({ pixCode, pixType }) => {
  const { t } = useTranslation();
  const handleCopyClick = () => {
    navigator.clipboard.writeText(pixCode);
    toast.info(t("order.pixCopied"), {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    console.log("copy");
  };

  return (
    <>
      <span>
        Faça o pagamento para o Pix abaixo e envie o comprovante ao seu promotor
      </span>
      <PixContainer>
        <div>
          <PixType>{pixType === "CPF" ? "CPF" : "Phone"}</PixType>
          <PixCode>{pixCode}</PixCode>
        </div>
        <CopyIcon onClick={handleCopyClick} size={18} />
      </PixContainer>
      <ToastContainer />
    </>
  );
};

export default Pix;
