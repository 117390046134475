import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { resetPassword } from "../services/api";
import {
  Container,
  Form,
  Title,
  Input,
  Button,
  ErrorMessage,
} from "../styles/ResetPasswordStyles";
import { useTranslation } from "react-i18next";

const ResetPasswordPage: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const { t } = useTranslation();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError(t("resetPassword.passwordMismatch"));
      return;
    }
    try {
      await resetPassword(token, password);
      navigate("/login");
    } catch (err) {
      setError(t("resetPassword.errorMessage"));
    }
  };

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Title>{t("resetPassword.title")}</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Input
          type="password"
          placeholder={t("resetPassword.newPasswordPlaceholder")}
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Input
          type="password"
          placeholder={t("resetPassword.confirmPasswordPlaceholder")}
          value={confirmPassword}
          onChange={(e: any) => setConfirmPassword(e.target.value)}
        />
        <Button type="submit">{t("resetPassword.saveButton")}</Button>
      </Form>
    </Container>
  );
};

export default ResetPasswordPage;
