// src/pages/GroupSummaryPage.tsx

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getGroupSummary } from "../services/api";
import { GroupSummaryDTO, DrawSummaryDTO, PromoterSummaryDTO } from "../types";
import NavBar from "../components/NavBar";
import {
  DrawCard,
  DrawId,
  PromoterCard,
  PromoterName,
  ValueTotal,
  SummaryInfo,
  SummaryInfoContainer,
  DrawState,
  DrawHeader,
} from "../styles/GroupSummaryStyles";
import {
  Container,
  BackButton,
  PageTitle,
  PageSubtitle,
} from "../styles/GlobalStyles";
import { useTranslation } from "react-i18next";

const GroupSummaryPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [groupSummary, setGroupSummary] = useState<GroupSummaryDTO | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchGroupSummary = async () => {
      try {
        if (id) {
          const response = await getGroupSummary(Number(id));
          setGroupSummary(response);
        }
      } catch (error) {
        setError("Error fetching group summary");
      }
    };

    fetchGroupSummary();
  }, [id]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container>
      <BackButton onClick={() => navigate("/group/" + groupSummary?.groupId)}>
        {"<"}
      </BackButton>
      <PageTitle>{t("group.summary")}</PageTitle>
      <PageSubtitle>{t("group.draws")}</PageSubtitle>
      {groupSummary &&
        groupSummary.drawsSummary
          .filter((d) => d.state !== "DELETED")
          .sort((a, b) => {
            return b.drawId - a.drawId;
          })
          .map((draw: DrawSummaryDTO) => (
            <DrawCard key={draw.drawId}>
              <DrawHeader>
                <DrawId>{`${draw.name}`}</DrawId>
                <DrawState state={draw.state}>
                  {" "}
                  {t(`drawState.${draw.state.toLowerCase()}`)}
                </DrawState>
              </DrawHeader>
              {draw.promotersSummary.map(
                (promoter: PromoterSummaryDTO, index: number) => (
                  <PromoterCard key={index}>
                    <PromoterName>{promoter.promoterName}</PromoterName>
                    <ValueTotal>{`$${promoter.valueTotal.toFixed(
                      2
                    )}`}</ValueTotal>
                  </PromoterCard>
                )
              )}
              <SummaryInfoContainer>
                <SummaryInfo>{`${t("group.total")}: $${draw.total.toFixed(
                  2
                )}`}</SummaryInfo>
                <SummaryInfo>{`${t("group.home")}: $${draw.valueHome.toFixed(
                  2
                )}`}</SummaryInfo>
              </SummaryInfoContainer>
            </DrawCard>
          ))}
      <NavBar />
    </Container>
  );
};

export default GroupSummaryPage;
