import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { register, existUsername, existEmail } from "../services/api";
import {
  Container,
  Form,
  Title,
  Input,
  Button,
  RegisterLink,
  ErrorMessage,
  PhoneContainer,
  CountryCode,
  RegionalCode,
  PhoneNumber,
  PasswordInput,
  NoteContainer,
} from "../styles/RegisterStyles";
import { UserDTO, PhoneDTO } from "../types";

const RegisterPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [regionalCode, setRegionalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");

  // Capture the redirectTo parameter from the URL
  const redirectTo = new URLSearchParams(location.search).get("redirectTo");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError(t("register.passwordMismatch"));
      return;
    }

    if (!username || !password) {
      setError(t("register.requiredFields"));
      return;
    }

    const phone: PhoneDTO = {
      countryCode: "+55",
      regionalCode: regionalCode.replace(/[()]/g, ""),
      phoneNumber: phoneNumber.replace(/-/g, ""),
      principal: true,
    };

    const newUser: Partial<UserDTO> = {
      username,
      name,
      email,
      password,
      phones: [phone],
      roles: [{ id: 1, name: "ROLE_USER" }],
    };

    try {
      await register(newUser);
      navigate(`/login?redirectTo=${encodeURIComponent(redirectTo || "/")}`);
    } catch (err) {
      setError(t("register.errorMessage"));
    }
  };

  const handleRegionalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    const formattedValue = value.replace(/(\d{2})/, "($1)"); // Add formatting
    setRegionalCode(formattedValue);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    const formattedValue = value.replace(/(\d{5})(\d{4})/, "$1-$2"); // Add formatting
    setPhoneNumber(formattedValue);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    setUsername(value);
    setUsernameError("");
  };

  const handleUsernameBlur = async () => {
    if (username) {
      try {
        const userExists = await existUsername(username);
        if (userExists) {
          setUsernameError(t("register.usernameTaken"));
        }
      } catch (err) {
        // handle error if necessary
      }
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9@._-]/g, "").toLowerCase();

    setEmail(value);
    setEmailError("");
  };

  const handleEmailBlur = async () => {
    if (email) {
      try {
        const emailExists = await existEmail(email);
        if (emailExists) {
          setEmailError(t("register.emailTaken"));
        }
      } catch (err) {
        // handle error if necessary
      }
    }
  };

  useEffect(() => {
    setError(""); // Clear error when user modifies input fields
  }, [
    username,
    name,
    email,
    password,
    confirmPassword,
    regionalCode,
    phoneNumber,
  ]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Title>{t("register.title")}</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {usernameError && <ErrorMessage>{usernameError}</ErrorMessage>}
        {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
        <Input
          type="text"
          placeholder={t("register.usernamePlaceholder")}
          value={username}
          onChange={handleUsernameChange}
          onBlur={handleUsernameBlur}
          required
          style={{ borderColor: usernameError ? "red" : undefined }}
          autoComplete="new-username"
        />
        <Input
          type="text"
          placeholder={t("register.namePlaceholder")}
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          autoComplete="name"
        />
        <Input
          type="email"
          placeholder={t("register.emailPlaceholder")}
          value={email}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          style={{ borderColor: emailError ? "red" : undefined }}
          autoComplete="email"
        />
        <PasswordInput
          type="password"
          placeholder={t("register.passwordPlaceholder")}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          error={
            !!password && !!confirmPassword && password !== confirmPassword
          }
          required
          autoComplete="new-password"
        />
        <PasswordInput
          type="password"
          placeholder={t("register.confirmPasswordPlaceholder")}
          value={confirmPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfirmPassword(e.target.value)
          }
          error={
            !!password && !!confirmPassword && password !== confirmPassword
          }
          required
        />
        <PhoneContainer>
          <CountryCode>🇧🇷+55</CountryCode>
          <RegionalCode
            type="text"
            placeholder={t("register.regionalCodePlaceholder")}
            value={regionalCode}
            onChange={handleRegionalCodeChange}
            maxLength={4} // (xx)
            required
            autoComplete="tel-national"
          />
          <PhoneNumber
            type="text"
            placeholder={t("register.phoneNumberPlaceholder")}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            maxLength={10} // xxxxx-xxxx
            required
            autoComplete="tel-national"
          />
        </PhoneContainer>
        <Button type="submit">{t("register.registerButton")}</Button>
        <RegisterLink onClick={() => navigate(`/login?redirectTo=${encodeURIComponent(redirectTo || "/")}`)}>
          {t("register.loginLink")}
        </RegisterLink>
        <NoteContainer>* campos obrigatórios</NoteContainer>
      </Form>
    </Container>
  );
};

export default RegisterPage;
