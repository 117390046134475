import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import NavBar from "../components/NavBar";

const CreateTicketPage: React.FC = () => {
  const [selectedNumbers, setSelectedNumbers] = useState<number[]>([]);

  const handleNumberClick = (number: number) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
    } else if (selectedNumbers.length < 6) {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };

  const handleSubmit = () => {
    // Lógica para enviar o ticket
    console.log("Ticket criado com os números:", selectedNumbers);
    // Resetar a seleção de números após a criação do ticket
    setSelectedNumbers([]);
  };

  return <></>;
};

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
`;

const NumberGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
  margin: 20px 0;
`;

const NumberButton = styled.button<{ selected: boolean }>`
  width: 40px;
  height: 40px;
  background-color: ${(props) => (props.selected ? "#6200ee" : "#fff")};
  color: ${(props) => (props.selected ? "#fff" : "#000")};
  border: 1px solid #ddd;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.selected ? "#6200ee" : "#f1f1f1")};
  }
`;

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export default CreateTicketPage;
