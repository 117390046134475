import React from "react";
import { useTranslation } from "react-i18next";
import { DrawDTO } from "../types"; // Importe o tipo DrawDTO

interface GroupGainProps {
  draw: DrawDTO;
}

const GroupGain: React.FC<GroupGainProps> = ({ draw }) => {
  const { t } = useTranslation();

  const calculateGain = () => {
    const gain =
      draw.valuePerTicket * draw.numberOfTickets -
      draw.valuePerTicket * draw.numberOfTickets * draw.percentHome;
    return gain.toFixed(2); // Arredonda para duas casas decimais
  };

  return <span>{`${t("group.gain")}: $${calculateGain()}`}</span>;
};

export default GroupGain;
