import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const AddToHomeScreenButton: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isIOS, setIsIOS] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;

    if (isIOSDevice) {
      const isAlreadyAdded = Cookies.get("a2hs_ios_added");
      if (!isAlreadyAdded) {
        setIsIOS(true);
        setIsVisible(true);
      }
    } else {
      const handleBeforeInstallPrompt = (event: any) => {
        event.preventDefault();
        setDeferredPrompt(event);
        const isAlreadyAdded = Cookies.get("a2hs_added");
        if (!isAlreadyAdded) {
          setIsVisible(true);
        }
      };

      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    }
  }, []);

  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
          Cookies.set("a2hs_added", "true", { expires: 30 }); // Set a cookie to expire in 30 days
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
        setIsVisible(false);
      });
    }
  };

  const handleClose = () => {
    if (isIOS) {
      Cookies.set("a2hs_ios_added", "true", { expires: 30 });
    }
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <PopupContainer>
      <PopupContent>
        <PopupTitle>{t("addToHome.title")}</PopupTitle>
        <PopupText>{t("addToHome.description")}</PopupText>
        {isIOS ? (
          <PopupText>{t("addToHome.ios_instructions")}</PopupText>
        ) : (
          <Button onClick={handleAddToHomeScreen}>
            {t("addToHome.button")}
          </Button>
        )}
        <CloseButton onClick={handleClose}>{t("addToHome.close")}</CloseButton>
      </PopupContent>
    </PopupContainer>
  );
};

export default AddToHomeScreenButton;

const PopupContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const PopupContent = styled.div`
  text-align: center;
`;

const PopupTitle = styled.h2`
  margin-bottom: 10px;
  color: #333;
`;

const PopupText = styled.p`
  margin-bottom: 20px;
  color: #666;
`;

const Button = styled.button`
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #3700b3;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #6200ee;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    color: #3700b3;
  }
`;
