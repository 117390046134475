// AuthContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  login as loginUser,
  setAuthToken,
  getUserDetails,
  getNotificationsForUser,
  clearNotificationForUser,
} from "../services/api";
import { UserDTO, NotificationMessageDTO } from "../types";
import { getTokenFromVapid } from "../firebase";
import axios from "../services/axiosConfig";
import { Client, IStompSocket } from "@stomp/stompjs";
import SockJS from "sockjs-client";

interface AuthContextType {
  user: UserDTO | null;
  isAuthenticated: boolean;
  handleLogin: (username: string, password: string, redirectTo?: string) => Promise<void>;
  handleLogout: () => void;
  notifications: NotificationMessageDTO[];
  unreadNotifications: number;
  resetUnreadNotifications: () => void;
  clearNotifications: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserDTO | null>(null);
  const [notifications, setNotifications] = useState<NotificationMessageDTO[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  const processedMessageIds = useRef<Set<string>>(new Set());
  const stompClientRef = useRef<Client | null>(null);
  const navigate = useNavigate();

  const fetchOfflineNotifications = async (userId: string) => {
    try {
      const response = await getNotificationsForUser(Number(userId));
      setNotifications(response);
      setUnreadNotifications(response.length);
    } catch (error) {
      console.error("Error fetching offline notifications:", error);
    }
  };

  const initializeWebSocket = (token: string, userId: string) => {
    if (stompClientRef.current) {
      return;
    }

    const client = new Client({
      connectHeaders: {
        Authorization: `Bearer ${token}`,
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.webSocketFactory = function () {
      return new SockJS(`https://www.sorterapida.com.br/ws?token=${token}`) as IStompSocket;
    };

    client.onConnect = (frame) => {
      client.subscribe(`/user/${userId}/topic/notifications`, function (message) {
        const notification: NotificationMessageDTO = JSON.parse(message.body);

        if (processedMessageIds.current.has(notification.id.toString())) {
          return;
        }

        processedMessageIds.current.add(notification.id.toString());

        setNotifications((prevNotifications) => {
          return [...prevNotifications, notification];
        });

        setUnreadNotifications((prevCount) => prevCount + 1);

        if (Notification.permission === "granted") {
          try {
            const notificationOptions = {
              body: notification.content,
            };
            new Notification("Nova Notificação", notificationOptions);
          } catch (error) {
            console.error("Error displaying notification:", error);
          }
        } else {
          console.log("Notificação não exibida. Permissão não concedida.");
        }
      });
    };

    client.onStompError = (frame) => {
      console.error("Broker reported error: " + frame.headers["message"]);
      console.error("Additional details: " + frame.body);
    };

    client.onWebSocketError = (evt) => {
      console.error("Error with websocket", evt);
    };

    client.activate();
    stompClientRef.current = client;
  };

  const handleLogin = async (username: string, password: string, redirectTo?: string) => {
    const response = await loginUser(username, password);
    const { jwt } = response;
    setAuthToken(jwt);
    localStorage.setItem("jwt", jwt);

    const userDetails = await getUserDetails();
    const userDetailsWithToken = {
      ...userDetails,
      token: jwt,
    };
    setUser(userDetailsWithToken);

    if (userDetails.id) {
      await fetchOfflineNotifications(userDetails.id.toString());
    }

    const fcmToken = await getTokenFromVapid();
    if (fcmToken) {
      await axios.post("/notifications/register", {
        userId: userDetails.id,
        token: fcmToken,
      });
    }

    initializeWebSocket(userDetailsWithToken.token, userDetails.id?.toString() ?? "");

    console.log("Login successful, navigating to:", redirectTo || "/home");
    if (redirectTo && redirectTo.includes("token")) {
      navigate(redirectTo);
    } else {
      navigate("/home");
    }
  };


  const handleLogout = () => {
    if (stompClientRef.current) {
      stompClientRef.current.deactivate();
      stompClientRef.current = null;
    }
    setUser(null);
    setAuthToken(null);
    localStorage.removeItem("jwt");
    navigate("/login");
  };

  const resetUnreadNotifications = () => {
    setUnreadNotifications(0);
  };

  const clearNotifications = () => {
    clearNotificationForUser(Number(user?.id));
    setNotifications([]);
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("jwt");
      if (token) {
        setAuthToken(token);
        try {
          const userDetails = await getUserDetails();
          setUser(userDetails);
          initializeWebSocket(token, userDetails.id?.toString() ?? "");

          if (userDetails.id) {
            await fetchOfflineNotifications(userDetails.id.toString());
          }
        } catch (error) {
          handleLogout();
        }
      }
    };
    checkAuth();
  }, []);

  useEffect(() => {}, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: !!user,
        handleLogin,
        handleLogout,
        notifications,
        unreadNotifications,
        resetUnreadNotifications,
        clearNotifications,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
