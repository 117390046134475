import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

export const NumberList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
`;

export const TicketNumberBall = styled.li<{ isDrawn: boolean }>`
  width: 40px;
  height: 40px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ isDrawn }) => (isDrawn ? "#4caf50" : "#ddd")};
  color: ${({ isDrawn }) => (isDrawn ? "#fff" : "#000")};
  font-weight: bold;
  cursor: pointer;
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const NumberListModal = styled(NumberList)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;
  justify-content: center;

  @media (max-width: 375px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 320px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const TicketNumberBallModal = styled(TicketNumberBall)``;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

export const ConfirmButton = styled.button<{ disabled: boolean }>`
  background-color: ${({ disabled }) => (disabled ? "#ddd" : "#6200ee")};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-left: 10px;
`;

export const CancelButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;

export const SurpresinhaButton = styled.button`
  background-color: #388e3c;
  color: white;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2), inset 0 -2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #2e7d32;
  }

  &:active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
      inset 0 -1px 2px rgba(0, 0, 0, 0.2);
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const TicketsCard = styled.div`
  background-color: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`;

export const TicketsTitle = styled.h3`
  margin: 0;
`;

export const AddTicketLink = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #6200ee;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #3700b3;
  }
`;

export const CopyTicketLink = styled.a`
  display: block;
  color: #6200ee;
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 5px;

  &:hover {
    color: #3700b3;
  }
`;

export const ModalTitle = styled.h3`
  margin-bottom: 20px;
`;

export const Button = styled.button`
  padding: 10px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #3700b3;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

export const AddTicketButton = styled(Button)`
  margin-top: 10px;
`;
