import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  getGroupById,
  API_BASE_URL,
  updateGroup,
  uploadImage,
} from "../services/api";
import { GroupDTO, ContactGroupDTO } from "../types";
import { useTranslation } from "react-i18next";
import {
  Form,
  ErrorMessage,
  Button,
  UploadContainer,
  GroupImage,
  Input,
  InfoMessage,
  EditIcon,
} from "../styles/GroupSettingsStyles";
import { Container, BackButton, PageTitle } from "../styles/GlobalStyles";
import LoadingOverlay from "../components/LoadingOverlay";
import NavBar from "../components/NavBar";
import ContactList from "../components/ContactList";

const GroupSettingsPage: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [group, setGroup] = useState<Partial<GroupDTO> | null>(null);
  const { id } = useParams<{ id: string }>();
  const [isManager, setIsManager] = useState(false);
  const [groupImage, setGroupImage] = useState<File | null>(null);
  const [groupImageURL, setGroupImageURL] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        if (id) {
          const response = await getGroupById(Number(id));
          setGroup(response);

          // Check if the logged-in user is a manager of this group
          const userIsManager = response?.idManager === user?.id;
          setIsManager(userIsManager);
        }
      } catch (error) {
        console.error("Error fetching group:", error);
      }
    };

    fetchGroup();
  }, [id, user]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setInfo("");
    if (file) {
      setIsLoading(true);
      try {
        const resizedImage = await resizeImage(file, 500, 500);
        setGroupImage(resizedImage);
        setGroupImageURL(URL.createObjectURL(resizedImage)); // Atualiza a URL da imagem para exibição imediata
      } catch (error) {
        console.error("Error resizing image:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resizeImage = (
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = (height * maxWidth) / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = (width * maxHeight) / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx!.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(resizedFile);
          } else {
            reject(new Error("Canvas is empty"));
          }
        }, file.type);
      };

      img.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("submitting");
    try {
      if (group && isManager) {
        setIsLoading(true);
        let imageUrl = group.image as string;
        if (groupImage) {
          const uploadResponse = await uploadImage(groupImage);
          imageUrl = uploadResponse.data;
        }

        const updatedGroup = await updateGroup({ ...group, image: imageUrl });
        setGroup((prevGroup) => ({ ...prevGroup, ...updatedGroup }));
        setInfo(t("group.saveSuccess"));
      }
    } catch (error) {
      setError(t("group.createError"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadClick = () => {
    document.getElementById("groupImageInput")?.click();
  };

  const handleUpdateContacts = (updatedContacts: ContactGroupDTO[]) => {
    setGroup((prevGroup) => ({ ...prevGroup, contacts: updatedContacts }));
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay />}
      <BackButton onClick={() => navigate(-1)}>{"<"}</BackButton>
      <Form onSubmit={handleSubmit}>
        <PageTitle>{t("group.settings")}</PageTitle>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {info && <InfoMessage>{info}</InfoMessage>}
        <UploadContainer onClick={handleUploadClick}>
          <GroupImage
            src={groupImageURL || `${API_BASE_URL}${group?.image}`}
            alt={group?.name}
          />
          <EditIcon />
        </UploadContainer>
        <Input
          id="groupImageInput"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />

        {group && (
          <ContactList
            contacts={group.contacts ?? []} // Use ?? para garantir que contacts não seja undefined
            onAddContact={(contact) =>
              handleUpdateContacts([...(group.contacts ?? []), contact])
            }
            onRemoveContact={(index) =>
              handleUpdateContacts(
                (group.contacts ?? []).filter((_, i) => i !== index)
              )
            }
          />
        )}

        <Button type="submit" disabled={!isManager}>
          {t("group.save")}
        </Button>
      </Form>
      <NavBar />
    </Container>
  );
};

export default GroupSettingsPage;
