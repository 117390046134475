import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateUser } from "../services/api";
import {
  Form,
  Title,
  Input,
  Button,
  ErrorMessage,
  PasswordInput,
  InfoMessage,
} from "../styles/RegisterStyles";
import { UserDTO } from "../types";
import NavBar from "../components/NavBar";
import { Container, BackButton } from "../styles/GlobalStyles";
import { useAuth } from "../contexts/AuthContext";

const ProfilePage: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      setName(user.name || "");
      setNickname(user.nickname || "");
      setEmail(user.email || "");
    }
  }, [user]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password && password !== confirmPassword) {
      setError(t("register.passwordMismatch"));
      return;
    }

    const updatedUser: Partial<UserDTO> = {
      id: user?.id,
      name,
      nickname,
      password: password || undefined, // only include password if it's filled
    };

    try {
      await updateUser(updatedUser);
      setInfo(t("profile.saveSuccess"));
      setError("");
    } catch (err) {
      setError(t("register.errorMessage"));
    }
  };

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)}>{"<"}</BackButton>
      <Form onSubmit={onSubmit}>
        <Title>{t("profile.title")}</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {info && <InfoMessage>{info}</InfoMessage>}
        <Input
          type="text"
          disabled={true}
          value={user?.username || ""}
        />
        <Input
          type="text"
          disabled={true}
          value={user?.email || ""}
        />
        <br/>
        <Input
          type="text"
          value={name}
          disabled={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          autoComplete="name"
        />
        <br/>
        <PasswordInput
          type="password"
          placeholder={t("register.passwordPlaceholder")}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          error={
            !!password && !!confirmPassword && password !== confirmPassword
          }
          autoComplete="new-password"
          style={{
            borderColor: !!password && !!confirmPassword && password !== confirmPassword ? 'red' : undefined,
          }}
        />
        <PasswordInput
          type="password"
          placeholder={t("register.confirmPasswordPlaceholder")}
          value={confirmPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfirmPassword(e.target.value)
          }
          error={
            !!password && !!confirmPassword && password !== confirmPassword
          }
          style={{
            borderColor: !!password && !!confirmPassword && password !== confirmPassword ? 'red' : undefined,
          }}
        />
        <Button type="submit">{t("profile.save")}</Button>
      </Form>
      <NavBar />
    </Container>
  );
};

export default ProfilePage;