import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import {
  Container,
  Form,
  Title,
  Input,
  Button,
  RegisterLink,
  ErrorMessage,
} from "../styles/LoginStyles";
import AddToHomeScreenButton from "../components/AddToHomeScreenButton";
import LoadingOverlay from "../components/LoadingOverlay";

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { handleLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const redirectTo =
    new URLSearchParams(location.search).get("redirectTo") || "/home";
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await handleLogin(username, password, redirectTo);
    } catch (err) {
      setError(t("login.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    setUsername(value);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay />}
      <Form onSubmit={onSubmit}>
        <Title>{t("login.title")}</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Input
          type="text"
          placeholder={t("login.usernamePlaceholder")}
          value={username}
          onChange={handleUsernameChange}
        />
        <Input
          type="password"
          placeholder={t("login.passwordPlaceholder")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit">{t("login.loginButton")}</Button>
        <RegisterLink
          onClick={() =>
            navigate(`/register?redirectTo=${encodeURIComponent(redirectTo)}`)
          }
        >
          {t("login.registerLink")}
        </RegisterLink>
        <br />
        <RegisterLink onClick={() => navigate(`/forgot-password`)}>
          {t("login.forget")}
        </RegisterLink>
      </Form>
      <AddToHomeScreenButton />
    </Container>
  );
};

export default LoginPage;
