// src/styles/GroupSummaryStyles.ts

import styled, { css } from "styled-components";

export const DrawCard = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: white;
  padding: 20px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

export const DrawId = styled.h2`
  margin: 0 0 10px;
`;

export const PromoterCard = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const PromoterName = styled.span`
  font-weight: bold;
`;

export const ValueTotal = styled.span`
  margin-top: 5px;
`;

export const SummaryInfoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
`;

export const SummaryInfo = styled.span`
  font-weight: bold;
  margin-top: 5px;
  color: #333;
`;

export const DrawState = styled.span<{ state: string }>`
  background-color: #6200ee;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;

  ${({ state }) =>
    state === "PRIZED" &&
    css`
      background-color: green;
    `}
`;

export const DrawHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
