import React, { useState, useEffect } from "react";
import { FinancialMovementDTO, PixType, UserGroupDTO } from "../types";
import { listByGroupAndPromoter } from "../services/api";
import {
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  Input,
  Button,
  MovementList,
  MovementItem,
  LoadMoreButton,
} from "../styles/GroupBalanceStyles";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../components/LoadingOverlay";
import { FaPhone, FaIdCard } from "react-icons/fa"; // Import icons

interface PromoterCardProps {
  promoter: UserGroupDTO;
  onAddMovement: (promoterId: number, amount: number) => void;
  onLimitChange: (promoterId: number, limit: number) => void;
  onPixChange: (promoterId: number, pixCode: string, pixType: PixType) => void;
  groupId: number;
}

const PromoterCard: React.FC<PromoterCardProps> = ({
  promoter,
  onAddMovement,
  onLimitChange,
  onPixChange,
  groupId,
}) => {
  const { t } = useTranslation();
  const [movements, setMovements] = useState<FinancialMovementDTO[]>([]);
  const [newAmount, setNewAmount] = useState<number | string>("");
  const [newLimit, setNewLimit] = useState<number | string>(
    promoter.limit || ""
  );
  const [newPixCode, setNewPixCode] = useState<string>(promoter.pixCode || "");
  const [newPixType, setNewPixType] = useState<PixType>(
    promoter.pixType || PixType.CPF
  );
  const [visibleMovements, setVisibleMovements] = useState<number>(3);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchMovements = async () => {
      try {
        if (promoter.user.id !== undefined) {
          setIsLoading(true);
          const response = await listByGroupAndPromoter(
            groupId,
            promoter.user.id
          );
          setMovements(response);
        }
      } catch (error) {
        console.error(t("balance.fetchMovementsError"), error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMovements();
  }, [groupId, promoter.user.id, t]);

  const handleAddMovement = async () => {
    if (newAmount && promoter.user.id !== undefined) {
      setIsLoading(true);
      try {
        await onAddMovement(promoter.user.id, Number(newAmount));
        setNewAmount("");

        // Fetch updated movements
        const response = await listByGroupAndPromoter(
          groupId,
          promoter.user.id
        );
        setMovements(response);
      } catch (error) {
        console.error(t("balance.addMovementError"), error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLimitChange = async () => {
    if (newLimit && promoter.user.id !== undefined) {
      setIsLoading(true);
      try {
        await onLimitChange(promoter.user.id, Number(newLimit));
      } catch (error) {
        console.error(t("balance.updateLimitError"), error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handlePixChange = async () => {
    if (newPixCode && newPixType && promoter.user.id !== undefined) {
      setIsLoading(true);
      try {
        await onPixChange(promoter.user.id, newPixCode, newPixType);
      } catch (error) {
        console.error(t("balance.updateLimitError"), error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLoadMore = () => {
    setVisibleMovements((prev) => prev + 5);
  };

  const formatDate = (dateString: string) => {
    if (dateString == null) {
      return null;
    }
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  return (
    <Card>
      {isLoading && <LoadingOverlay />}
      <CardTitle>{promoter.user.name}</CardTitle>
      <CardBody>
        <div>
          <span>{`${t("balance.pix")}: ${
            promoter.pixCode !== undefined && promoter.pixCode !== null
              ? promoter.pixCode
              : ""
          }`}</span>
          <br />

          {/* Dropdown for PixType and PixCode input on the same line */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <select
              value={newPixType}
              onChange={(e) => setNewPixType(e.target.value as PixType)}
              style={{
                marginRight: "10px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
              }}
            >
              <option value={PixType.CPF}>{t("balance.cpf")}</option>
              <option value={PixType.PHONE}>{t("balance.phone")}</option>
            </select>

            <Input
              type="text"
              value={newPixCode}
              onChange={(e) => setNewPixCode(e.target.value)}
              placeholder={t("balance.newPix")}
              style={{
                flex: 1, // To make the input take the remaining space
                padding: "10px",
                marginTop: "0px",
                borderRadius: "5px",
                border: "1px solid #ddd",
              }}
            />
          </div>
        </div>
        <div>
          <Button onClick={handlePixChange}>{t("balance.updatePix")}</Button>
        </div>

        <br />

        <div>
          <div>
            <span>{`${t("balance.limit")}: ${
              promoter.limit !== null
                ? promoter.limit.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                : ""
            }`}</span>
            <br />
            <Input
              type="text"
              value={newLimit}
              onChange={(e) => setNewLimit(e.target.value)}
              placeholder={t("balance.newLimit")}
            />
          </div>
          <div>
            <Button onClick={handleLimitChange}>
              {t("balance.updateLimit")}
            </Button>
          </div>
        </div>
        <br />
        <div>
          <Input
            type="text"
            value={newAmount}
            onChange={(e) => setNewAmount(e.target.value)}
            placeholder={t("balance.amount")}
          />
          <Button onClick={handleAddMovement}>
            {t("balance.addMovement")}
          </Button>
        </div>
        <MovementList>
          {movements
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            ) // Sort by date, most recent first
            .slice(0, visibleMovements)
            .map((movement) => (
              <MovementItem
                key={movement.id}
                style={{
                  color: movement.direction === "IN" ? "green" : "red",
                }}
              >
                {movement.direction === "IN"
                  ? t("balance.received")
                  : t("balance.validated")}{" "}
                -{" "}
                {movement.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
                {t("balance.on")} {formatDate(movement.date)}
              </MovementItem>
            ))}
        </MovementList>
        {visibleMovements < movements.length && (
          <LoadMoreButton onClick={handleLoadMore}>
            {t("balance.showMore")}
          </LoadMoreButton>
        )}
      </CardBody>
      <CardFooter />
    </Card>
  );
};

export default PromoterCard;
