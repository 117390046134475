import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getUsersByGroupId,
  promoteToPromoter,
  demotePromoter,
  removeFromGroup,
  defineUserPromoter,
} from "../services/api";
import { UserGroupDTO, UserDTO } from "../types";
import NavBar from "../components/NavBar";
import { useTranslation } from "react-i18next";
import {
  UserCard,
  UserName,
  RoleBadge,
  Modal,
  ModalContent,
  ModalButton,
  ModalOverlay,
  ExclamationIcon,
  StyledSelect,
  UserList,
  SearchContainer,
  SearchInput,
  WhatsAppLink,
} from "../styles/GroupUsersStyles";
import {
  Container,
  BackButton,
  PageTitle,
  CancelButton,
} from "../styles/GlobalStyles";
import { useAuth } from "../contexts/AuthContext";
import Select from "react-select";
import { FaWhatsapp } from "react-icons/fa";

const GroupUsersPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [users, setUsers] = useState<UserGroupDTO[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserGroupDTO | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (id) {
          const response = await getUsersByGroupId(Number(id));
          setUsers(response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [id]);

  const isManager = users.some(
    (userGroup) =>
      userGroup.user.id === user?.id && userGroup.role.name === "ROLE_MANAGER"
  );

  const isPromoter = users.some(
    (userGroup) =>
      userGroup.user.id === user?.id && userGroup.role.name === "ROLE_PROMOTER"
  );

  const handleUserClick = (userGroup: UserGroupDTO) => {
    if (isManager && userGroup.role.name !== "ROLE_MANAGER") {
      setSelectedUser(userGroup);
    }
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
  };

  const handlePromote = async () => {
    if (selectedUser) {
      try {
        await promoteToPromoter(Number(id), Number(selectedUser.user.id));
        setUsers(
          users.map((user) =>
            user.user.id === selectedUser.user.id
              ? { ...user, role: { ...user.role, name: "ROLE_PROMOTER" } }
              : user
          )
        );
        handleCloseModal();
      } catch (error) {
        console.error("Error promoting user:", error);
      }
    }
  };

  const handleDemote = async () => {
    if (selectedUser) {
      try {
        await demotePromoter(Number(id), Number(selectedUser.user.id));
        setUsers(
          users.map((user) =>
            user.user.id === selectedUser.user.id
              ? { ...user, role: { ...user.role, name: "ROLE_USER" } }
              : user
          )
        );
        handleCloseModal();
      } catch (error) {
        console.error("Error demoting user:", error);
      }
    }
  };

  const handleRemove = async () => {
    if (selectedUser) {
      try {
        await removeFromGroup(Number(id), selectedUser.user);
        setUsers(users.filter((user) => user.user.id !== selectedUser.user.id));
        handleCloseModal();
      } catch (error) {
        console.error("Error removing user:", error);
      }
    }
  };

  const handlePromoterChange = async (selectedOption: any) => {
    if (selectedUser && selectedOption.value) {
      try {
        await defineUserPromoter(
          Number(id),
          Number(selectedUser.user.id),
          Number(selectedOption.value.id)
        );

        setUsers(
          users.map((user) =>
            user.user.id === selectedUser.user.id
              ? { ...user, promoter: selectedOption.value }
              : user
          )
        );

        handleCloseModal();
      } catch (error) {
        console.error("Error demoting user:", error);
      }
    }
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (a.role.name === "ROLE_MANAGER") return -1;
    if (b.role.name === "ROLE_MANAGER") return 1;
    if (a.role.name === "ROLE_PROMOTER") return -1;
    if (b.role.name === "ROLE_PROMOTER") return 1;
    return 0;
  });

  const filteredUsers = isPromoter
    ? sortedUsers.filter(
        (userGroup: UserGroupDTO) =>
          userGroup.user.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) &&
          userGroup.promoter.id === user?.id
      )
    : sortedUsers.filter((userGroup: UserGroupDTO) =>
        userGroup.user.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

  return (
    <Container>
      <BackButton onClick={() => navigate("/group/" + id)}>{"<"}</BackButton>
      <PageTitle>{t("group.members")}</PageTitle>
      <SearchContainer className="search-container">
        <SearchInput
          type="text"
          placeholder={t("draw.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchContainer>
      <UserList>
        {filteredUsers.map((userGroup) => (
          <UserCard
            key={userGroup.user.id}
            onClick={() => handleUserClick(userGroup)}
          >
            <UserName>{userGroup.user.name}</UserName>
            <WhatsAppLink
              href={
                `https://wa.me/${
                  userGroup.user.phones.find((phone) => phone.principal)
                    ?.countryCode
                }` +
                `${
                  userGroup.user.phones.find((phone) => phone.principal)
                    ?.regionalCode
                }` +
                `${
                  userGroup.user.phones.find((phone) => phone.principal)
                    ?.phoneNumber
                }`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp size={20} />
              {t("group.contactUser")}
            </WhatsAppLink>
            {(userGroup.role.name === "ROLE_MANAGER" ||
              userGroup.role.name === "ROLE_PROMOTER") && (
              <RoleBadge role={userGroup.role.name}>
                {t(
                  `role.${userGroup.role.name
                    .toLowerCase()
                    .replace("role_", "")}`
                )}
              </RoleBadge>
            )}
          </UserCard>
        ))}
      </UserList>
      {selectedUser && selectedUser.role.name !== "ROLE_MANAGER" && (
        <>
          <ModalOverlay />
          <Modal>
            <ModalContent>
              <UserName>{selectedUser.user.name}</UserName>
              <br />
              <StyledSelect
                options={users
                  .filter(
                    (promoter) =>
                      promoter.role.name === "ROLE_PROMOTER" ||
                      promoter.role.name === "ROLE_MANAGER"
                  )
                  .map((promoter) => ({
                    value: promoter.user,
                    label: promoter.user.name,
                  }))}
                onChange={handlePromoterChange}
                placeholder={selectedUser?.promoter?.name}
              />
              <br />
              <ModalButton onClick={handleRemove}>
                {t("actions.removeUser")}
                <ExclamationIcon />
              </ModalButton>
              {selectedUser.role.name === "ROLE_USER" && (
                <ModalButton onClick={handlePromote}>
                  {t("actions.promoteToPromoter")}
                </ModalButton>
              )}
              {selectedUser.role.name === "ROLE_PROMOTER" && (
                <ModalButton onClick={handleDemote}>
                  {t("actions.demotePromoter")}
                </ModalButton>
              )}
              <br />
              <CancelButton onClick={handleCloseModal}>
                {t("actions.cancel")}
              </CancelButton>
            </ModalContent>
          </Modal>
        </>
      )}
      <NavBar />
    </Container>
  );
};

export default GroupUsersPage;
