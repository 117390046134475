import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { acceptInvite } from "../services/api";
import { Button, ErrorMessage, Form } from "../styles/CreateGroupStyles";
import { useTranslation } from "react-i18next";
import { Container, CancelButton } from "../styles/GlobalStyles";
import LoadingOverlay from "../components/LoadingOverlay";

const InvitePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { user, isAuthenticated } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [inviteAccepted, setInviteAccepted] = useState(false);
  const [authCheckedCount, setAuthCheckedCount] = useState(0);

  const handleAcceptInvite = async () => {
    setLoading(true);
    try {
      if (token && user) {
        console.log("Start calling acceptInvite");
        const result = await acceptInvite(token, Number(user.id));
        console.log("End calling acceptInvite", result);
        setInviteAccepted(result);
        if (!result) {
          setError(t("invite.invalidToken"));
        }
      }
    } catch (error) {
      console.error("Error in handleAcceptInvite:", error);
      setError(t("invite.invalidToken"));
    } finally {
      setLoading(false);
      console.log("Finally block - isAuthenticated:", isAuthenticated);
      console.log("Finally block - inviteAccepted:", inviteAccepted);
      if (isAuthenticated && inviteAccepted) {
        navigate("/home");
      } else {
        const redirectTo = encodeURIComponent(window.location.pathname + window.location.search);
        console.log("Redirecting to login with redirectTo:", redirectTo);
        navigate(`/login?redirectTo=${redirectTo}`);
      }
    }
  };

  const handleCancel = () => {
    navigate("/home");
  };

  useEffect(() => {
    if (!user && authCheckedCount < 3) {
      setAuthCheckedCount((count) => count + 1);
    } else if (!user) {
      const redirectTo = encodeURIComponent(window.location.pathname + window.location.search);
      console.log("User not found, redirecting to login with redirectTo:", redirectTo);
      navigate(`/login?redirectTo=${redirectTo}`);
    }
  }, [user, authCheckedCount, navigate]);

  return (
    <Container>
      {loading && <LoadingOverlay />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!user ? (
        <Form>
          <h2>{t("invite.loginOrRegister")}</h2>
          <Button onClick={() => navigate(`/login?redirectTo=${encodeURIComponent(window.location.pathname + window.location.search)}`)}>
            {t("login.title")}
          </Button>
          <Button onClick={() => navigate(`/register?redirectTo=${encodeURIComponent(window.location.pathname + window.location.search)}`)}>
            {t("register.title")}
          </Button>
        </Form>
      ) : (
        <Form>
          <h2>{t("invite.invitation")}</h2>
          <Button onClick={handleAcceptInvite}>{t("invite.acceptInvite")}</Button>
          <br/>
          <CancelButton onClick={handleCancel}>{t("invite.cancel")}</CancelButton>
        </Form>
      )}
    </Container>
  );
};

export default InvitePage;
