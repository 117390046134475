import React, { useState } from "react";
import { requestPasswordReset } from "../services/api";
import {
  Container,
  Form,
  Title,
  Input,
  Button,
  ErrorMessage,
  InfoMessage,
} from "../styles/ResetPasswordStyles";
import { BackButton } from "../styles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const RequestResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const navigate = useNavigate();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Chama a função para solicitar a geração do token de redefinição de senha
      await requestPasswordReset(email);

      // Define a mensagem de confirmação após o sucesso
      setInfo(t("email.successMessage"));
      setError("");
    } catch (err) {
      // Define a mensagem de erro caso falhe
      setError(t("email.errorMessage"));
      setInfo("");
    }
  };

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)}>{"<"}</BackButton>
      <Form onSubmit={onSubmit}>
        <Title>{t("email.title")}</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {info && <InfoMessage>{info}</InfoMessage>}
        <Input
          type="email"
          placeholder={t("email.placeholder")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit">{t("email.sendButton")}</Button>
      </Form>
    </Container>
  );
};

export default RequestResetPasswordPage;
