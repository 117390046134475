// src/components/LoadingOverlay.tsx
import React from "react";
import { FaSpinner } from "react-icons/fa";
import "../styles/LoadingOverlay.css"; // Importa o arquivo CSS

const LoadingOverlay: React.FC = () => (
  <div className="loading-overlay">
    <FaSpinner size={60} color="white" className="spin" />
  </div>
);

export default LoadingOverlay;
