import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  getLatestTickets,
  createOrder,
  getDrawById,
  getUsersByGroupId,
} from "../services/api";
import { UserDTO, TicketDTO, OrderDTO, DrawDTO, UserGroupDTO } from "../types";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import {
  Form,
  ErrorMessage,
  TicketNumberBall,
  NumberList,
  Modal,
  ModalContent,
  ModalOverlay,
  NumberListModal,
  TicketNumberBallModal,
  ModalButtonContainer,
  ConfirmButton,
  ModalTitle,
  Button,
  TicketsCard,
  TicketsTitle,
  AddTicketLink,
  SurpresinhaButton,
  CopyTicketLink,
} from "../styles/CreateOrderStyles";
import { Container, BackButton, CancelButton } from "../styles/GlobalStyles";
import { GiShamrock } from "react-icons/gi";
import LoadingOverlay from "../components/LoadingOverlay";
import { IoMdRemoveCircle } from "react-icons/io";

const CreateOrderPage: React.FC = () => {
  const { drawId, userId } = useParams<{ drawId: string; userId: string }>();
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [promoters, setPromoters] = useState<UserDTO[]>([]);
  const [selectedPromoter, setSelectedPromoter] = useState<UserDTO | null>(
    null
  );
  const [tickets, setTickets] = useState<Partial<TicketDTO>[]>([]);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedNumbers, setSelectedNumbers] = useState<number[]>([]);
  const [ticketValue, setTicketValue] = useState<number>(0);
  const [draw, setDraw] = useState<DrawDTO | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDrawDetails = async () => {
      try {
        const response: DrawDTO = await getDrawById(Number(drawId));
        setDraw(response);
        setTicketValue(response.valuePerTicket);

        const userGroups = await getUsersByGroupId(Number(response.groupId));
        const userGroup = userGroups.find(
          (userGroup: UserGroupDTO) => userGroup.user.id === Number(userId)
        );

        setSelectedPromoter(userGroup?.promoter);

        if (userGroup) {
          setPromoters([userGroup.promoter]);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching draw details:", error);
      }
    };

    const fetchPromoters = async () => {
      try {
        /* const response = await getPromotersByGroupId(Number(draw?.groupId));
        if (response) {
          setPromoters([response]);
        } */
      } catch (error) {
        console.error("Error fetching promoters:", error);
        setLoading(false);
      }
    };

    fetchDrawDetails();
    fetchPromoters();
  }, [drawId, draw?.groupId]);

  const handlePromoterChange = (selectedOption: any) => {
    setSelectedPromoter(selectedOption.value);
  };

  const handleAddTicket = () => {
    setShowModal(true);
  };

  const handleNumberClick = (number: number) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
    } else if (selectedNumbers.length < 6) {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };

  const handleModalClose = () => {
    setSelectedNumbers([]);
    setShowModal(false);
  };

  const handleAddTicketConfirm = () => {
    if (selectedNumbers.length === 6) {
      const newTicket: Partial<TicketDTO> = {
        numbers: selectedNumbers.map((number) => ({
          id: number,
          number,
          isDrawn: false,
          resultId: null,
        })),
        drawId: Number(drawId),
        userId: Number(userId),
        state: "PENDING",
      };
      setTickets([...tickets, newTicket]);
      handleModalClose();
    }
  };

  const calculateTotal = () => {
    return tickets.length * ticketValue;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (user && selectedPromoter && tickets.length > 0) {
        const newOrder: Partial<OrderDTO> = {
          id: Date.now(), // Temporary id
          pin: "some-pin", // Generate or fetch a PIN if necessary
          user,
          promoter: selectedPromoter,
          draw: { id: Number(drawId) } as any, // Adjust as necessary if more draw details are needed
          tickets: tickets as TicketDTO[], // Cast to full TicketDTO if necessary
          state: "PENDING",
          total: calculateTotal(),
        };
        await createOrder(newOrder);
        navigate(`/draw/${drawId}/orders`);
      }
    } catch (error) {
      setError(t("order.createError"));
    } finally {
      setLoading(false);
    }
  };

  const handleSurpresinha = () => {
    const randomNumbers = new Set<number>();
    while (randomNumbers.size < 6) {
      const randomNumber = Math.floor(Math.random() * 60) + 1;
      randomNumbers.add(randomNumber);
    }
    setSelectedNumbers(Array.from(randomNumbers));
  };

  const handleCopyFromLastDraw = async () => {
    try {
      const latestTickets = await getLatestTickets(
        Number(drawId),
        Number(userId)
      );
      if (latestTickets.length > 0) {
        setTickets((prevTickets) => {
          // Filtra tickets que já existem no estado atual
          const existingTicketNumbers = new Set(
            prevTickets.map((ticket) =>
              JSON.stringify(ticket.numbers?.map((num) => num.number).sort())
            )
          );

          const newTickets = latestTickets
            .filter((ticket: TicketDTO) => {
              const ticketNumbers = JSON.stringify(
                ticket.numbers.map((num) => num.number).sort()
              );
              return !existingTicketNumbers.has(ticketNumbers);
            })
            .map((ticket: TicketDTO) => ({
              numbers: ticket.numbers.map((number) => ({
                id: number.id,
                number: number.number,
                isDrawn: false,
                resultId: null,
              })),
              drawId: Number(drawId),
              userId: Number(userId),
              state: "PENDING",
            }));

          return [...prevTickets, ...newTickets];
        });
      } else {
        setError(t("order.copyError"));
      }
    } catch (error) {
      setError(t("order.copyError"));
    }
  };

  const handleRemoveTicket = (index: number, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setTickets((prevTickets) => [
      ...prevTickets.slice(0, index),
      ...prevTickets.slice(index + 1),
    ]);
  };

  const isSubmitDisabled = !selectedPromoter || tickets.length === 0;

  return (
    <Container>
      {loading && <LoadingOverlay />}
      <BackButton onClick={() => navigate(-1)}>{"<"}</BackButton>
      <Form onSubmit={handleSubmit}>
        <h2>{t("order.createTitle")}</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Select
          options={promoters.map((promoter) => ({
            value: promoter,
            label: promoter.name,
          }))}
          onChange={handlePromoterChange}
          isDisabled={true}
          placeholder={t("order.promoter") + ": " + selectedPromoter?.name}
        />
        <TicketsCard>
          <TicketsTitle>{t("order.tickets")}</TicketsTitle>
          <AddTicketLink onClick={handleAddTicket}>
            {tickets.length > 0
              ? "Adicione mais bilhetes"
              : t("order.addTicket")}
          </AddTicketLink>
          <br />
          <CopyTicketLink onClick={handleCopyFromLastDraw}>
            {t("order.copyFromLastDraw")}
          </CopyTicketLink>
          <ul>
            {tickets.map((ticket, index) => (
              <li key={index}>
                <NumberList>
                  {ticket.numbers?.map((num) => (
                    <TicketNumberBall key={num.number} isDrawn={num.isDrawn}>
                      {num.number}
                    </TicketNumberBall>
                  ))}
                  <CancelButton
                    onClick={(event) => handleRemoveTicket(index, event)}
                  >
                    <IoMdRemoveCircle color="red" />
                  </CancelButton>
                </NumberList>
              </li>
            ))}
          </ul>
        </TicketsCard>
        <div
          style={{
            textAlign: "right",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          {`${t("order.total")}: $${calculateTotal().toFixed(2)}`}
        </div>
        <Button type="submit" disabled={isSubmitDisabled}>
          {t("order.createButton")}
        </Button>
      </Form>

      {showModal && (
        <>
          <ModalOverlay onClick={handleModalClose} />
          <Modal>
            <ModalContent>
              <ModalTitle>{t("order.selectNumbers")}</ModalTitle>
              <NumberListModal>
                {Array.from({ length: 60 }, (_, i) => i + 1).map((number) => (
                  <TicketNumberBallModal
                    key={number}
                    isDrawn={selectedNumbers.includes(number)}
                    onClick={() => handleNumberClick(number)}
                  >
                    {number}
                  </TicketNumberBallModal>
                ))}
              </NumberListModal>
              <ModalButtonContainer>
                <CancelButton onClick={handleModalClose}>
                  {t("order.cancel")}
                </CancelButton>
                <SurpresinhaButton onClick={handleSurpresinha}>
                  <GiShamrock />
                </SurpresinhaButton>
                <ConfirmButton
                  onClick={handleAddTicketConfirm}
                  disabled={selectedNumbers.length !== 6}
                >
                  {t("order.confirmTicket")}
                </ConfirmButton>
              </ModalButtonContainer>
            </ModalContent>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default CreateOrderPage;
