import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  getPromotersByGroupId,
  defineUserPromoterLimit,
  addMovement,
  defineUserPromoterPix,
} from "../services/api";
import { PixType, UserGroupDTO } from "../types";
import { useTranslation } from "react-i18next";
import { PageTitle, Container, BackButton } from "../styles/GlobalStyles";
import {
  CardList,
  ErrorMessage,
  InfoMessage,
} from "../styles/GroupBalanceStyles";
import LoadingOverlay from "../components/LoadingOverlay";
import NavBar from "../components/NavBar";
import PromoterCard from "../components/PromoterCard";

const GroupBalancePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: groupId } = useParams<{ id: string }>();
  const { user } = useAuth();
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [promoters, setPromoters] = useState<UserGroupDTO[]>([]);

  const fetchPromoters = async () => {
    try {
      if (groupId) {
        const response = await getPromotersByGroupId(Number(groupId));
        setPromoters(response);
      }
    } catch (error) {
      setError(t("balance.fetchError"));
    }
  };

  useEffect(() => {
    fetchPromoters();
  }, [groupId, t]);

  const handleAddMovement = async (promoterId: number, amount: number) => {
    try {
      if (groupId) {
        await addMovement(Number(groupId), promoterId, amount);
        setInfo(t("balance.movementAdded"));
        fetchPromoters(); // Refresh the entire list or just the specific promoter's movements
      }
    } catch (error) {
      setError(t("balance.addMovementError"));
    }
  };

  const handleLimitChange = async (promoterId: number, limit: number) => {
    try {
      if (groupId) {
        await defineUserPromoterLimit(Number(groupId), promoterId, limit);
        setInfo(t("balance.limitUpdated"));
        fetchPromoters(); // Refresh promoters list
      }
    } catch (error) {
      setError(t("balance.limitUpdated"));
    }
  };

  const handlePixChange = async (
    promoterId: number,
    pixCode: string,
    pixType: PixType
  ) => {
    try {
      if (groupId) {
        await defineUserPromoterPix(
          Number(groupId),
          promoterId,
          pixCode,
          pixType
        );
        setInfo(t("balance.pixUpdated"));
        fetchPromoters(); // Refresh promoters list
      }
    } catch (error) {
      setError(t("balance.updatePixError"));
    }
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay />}
      <BackButton onClick={() => navigate(-1)}>{"<"}</BackButton>
      <PageTitle>{t("balance.balanceTitle")}</PageTitle>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {info && <InfoMessage>{info}</InfoMessage>}
      <CardList>
        {promoters.map((promoter) => (
          <>
            {groupId && (
              <PromoterCard
                key={promoter.user.id}
                promoter={promoter}
                groupId={Number(groupId)} // Converter para número
                onAddMovement={handleAddMovement}
                onLimitChange={handleLimitChange}
                onPixChange={handlePixChange}
              />
            )}
          </>
        ))}
      </CardList>
      <NavBar />
    </Container>
  );
};

export default GroupBalancePage;
