import styled from "styled-components";

export const ContactListContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ContactTable = styled.div`
  width: 100%;
`;

export const ContactTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
`;

export const ContactTableCell = styled.div<{ width?: string }>`
  flex: ${(props) => props.width || "1"};
  padding: 0 8px;
  text-align: left;
  inline-size: 120px;
  overflow-wrap: break-word;
`;

export const AddContactButton = styled.button`
  background-color: transparent;
  color: #007bff;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  margin-bottom: 10px;
  align-self: flex-end;
  width: 100%;
  max-width: 400px;
  text-align: right;
`;

export const RemoveContactButton = styled.button`
  background-color: transparent;
  color: red;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  text-align: right;
  width: 100%;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

export const ConfirmButton = styled.button<{ disabled: boolean }>`
  background-color: ${({ disabled }) => (disabled ? "#ddd" : "#6200ee")};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-left: 10px;
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const Select = styled.select`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const PhoneContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  width: 100%;
`;

export const CountryCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f5f5f5;
  margin-right: 10px;
  font-size: 16px;
  height: 42px; /* Matches the height of Input elements */
  box-sizing: border-box;
`;

export const RegionalCode = styled(Input)`
  width: 60px;
  margin-right: 10px;
  box-sizing: border-box;
`;

export const PhoneNumber = styled(Input)`
  flex: 1;
  box-sizing: border-box;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;
