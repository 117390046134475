// src/pages/GroupPage.tsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getGroupById,
  activateGroup,
  closeGroup,
  API_BASE_URL,
  deleteGroup,
  generateInviteLink,
} from "../services/api";
import { GroupDTO, UserGroupDTO } from "../types";
import { useTranslation } from "react-i18next";
import NavBar from "../components/NavBar";
import {
  GroupImage,
  GroupName,
  DrawTitle,
  DrawCard,
  DrawHeader,
  DrawId,
  DrawState,
  DrawInProgressDate,
  DrawDetails,
  ValueDetails,
  ContactManagerContainer,
  ContactManagerLink,
  Modal,
  ModalButton,
  ModalContent,
  ModalOverlay,
  ContactTableCell,
  ContactTableRow,
  ModalContact,
  ModalContactContent,
  ContactShareLink,
  ShareLinkContainer,
  ShareLinkInput,
  CopyIcon,
  WhatsappButton,
} from "../styles/GroupStyles";
import {
  Container,
  BackButton,
  CreateIcon,
  SettingsButton,
  CancelButton,
} from "../styles/GlobalStyles";
import GroupGain from "../components/GroupGain";
import { useAuth } from "../contexts/AuthContext"; // Import useAuth
import { FaWhatsapp, FaUsers } from "react-icons/fa";
import { useGroup } from "../contexts/GroupContext";
import { CiShare1 } from "react-icons/ci";
import LoadingOverlay from "../components/LoadingOverlay";

const GroupPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth(); // Get the logged in user
  const [group, setGroup] = useState<GroupDTO | null>(null);
  const [isManager, setIsManager] = useState(false);
  const [isPromoter, setIsPromoter] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showContactsModal, setShowContactsModal] = useState(false);
  const { clearGroup } = useGroup();
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        setIsLoading(true);
        if (id) {
          const response = await getGroupById(Number(id));
          setGroup(response);

          group?.draws.sort((a, b) => {
            return b.id - a.id;
          });
          // Check if the logged-in user is a manager of this group
          const userIsManager = response?.idManager === user?.id;
          setIsManager(userIsManager);
          const userIsPromoter = response.promoters.some(
            (promoter: number) => promoter === user?.id
          );
          setIsPromoter(userIsPromoter);
        }
      } catch (error) {
        console.error("Error fetching group:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGroup();
  }, [id, user]);

  const handleDrawClick = (drawId: number) => {
    navigate(`/draw/${drawId}`);
  };

  const handleCreateDrawClick = () => {
    navigate(`/draw/${id}/create`);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const managerPhone = group?.managerPhone;

  const handleAdminClick = () => {
    setShowAdminModal(true);
  };

  const handleCloseAdminModal = () => {
    setShowAdminModal(false);
  };

  const handleContactsClick = () => {
    setShowContactsModal(true);
  };

  const handleCloseContactsModal = () => {
    setShowContactsModal(false);
  };

  const handleClose = async () => {
    if (group) {
      try {
        await closeGroup(group.id);
        handleCloseAdminModal();
      } catch (error) {
        console.error("Error closing group:", error);
      }
    }
  };

  const handleActivate = async () => {
    if (group) {
      try {
        await activateGroup(group.id);
        handleCloseAdminModal();
      } catch (error) {
        console.error("Error activating group:", error);
      }
    }
  };

  const handleDelete = async () => {
    if (group) {
      try {
        await deleteGroup(group.id);
        handleCloseAdminModal();
        navigate(`/home`);
      } catch (error) {
        console.error("Error deleting group:", error);
      }
    }
  };

  const handleBackButton = async () => {
    clearGroup();
    navigate("/home");
  };

  const handleGenerateShareLink = async () => {
    if (group) {
      try {
        const response = await generateInviteLink(group.id, user?.id as number);
        setShareLink(response);
        setShowShareModal(true);
      } catch (error) {
        console.error("Error generating share link:", error);
      }
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const isAdmin = user?.roles.some((role) => role.name === "ROLE_ADMIN");

  return (
    <Container>
      {isLoading && <LoadingOverlay />}
      <BackButton onClick={handleBackButton}>{"<"}</BackButton>
      {group && (
        <>
          {isAdmin && <SettingsButton onClick={() => handleAdminClick()} />}
          <GroupImage src={`${API_BASE_URL}${group.image}`} />
          <GroupName>{group.name}</GroupName>

          <ContactManagerContainer>
            <ContactManagerLink onClick={handleContactsClick}>
              <FaWhatsapp size={20} />
              {t("group.contact")}
            </ContactManagerLink>
            {(isManager || isPromoter) && (
              <ContactShareLink onClick={handleGenerateShareLink}>
                <CiShare1 />
              </ContactShareLink>
            )}
          </ContactManagerContainer>

          <DrawTitle>{t("group.draws")}</DrawTitle>
          {group.draws
            .sort((a, b) => {
              return b.id - a.id;
            })
            .map((draw) => (
              <DrawCard
                state={draw.state}
                key={draw.id}
                onClick={() => handleDrawClick(draw.id)}
              >
                <DrawHeader>
                  <DrawId>{`${draw.name}`}</DrawId>
                  <DrawState state={draw.state}>
                    {" "}
                    {t(`drawState.${draw.state.toLowerCase()}`)}
                  </DrawState>
                </DrawHeader>
                {draw.state !== "INITIATED" && (
                  <DrawInProgressDate>
                    {draw.state === "IN_PROGRESS"
                      ? formatDate(draw.inProgressDate)
                      : formatDate(draw.prizedDate)}
                  </DrawInProgressDate>
                )}
                {group.draws.length > 0 &&
                  (draw.state === "IN_PROGRESS" || draw.state === "PRIZED") && (
                    <DrawDetails>
                      <span>
                        {`${t("group.participants")}: ${draw.numberOfUsers}`}
                      </span>
                      <span>{`${t("group.activeTickets")}: ${
                        draw.numberOfTickets
                      }`}</span>
                    </DrawDetails>
                  )}

                <ValueDetails>
                  <span>{`${t("group.valuePerTicket")}: $${
                    draw.valuePerTicket
                  }`}</span>
                  {group.draws.length > 0 &&
                    (draw.state === "IN_PROGRESS" ||
                      draw.state === "PRIZED") && <GroupGain draw={draw} />}
                </ValueDetails>
              </DrawCard>
            ))}
        </>
      )}
      {isManager && group?.state === "ACTIVE" && (
        <CreateIcon onClick={() => handleCreateDrawClick()} />
      )}{" "}
      {showAdminModal && (
        <>
          <ModalOverlay />
          <Modal>
            <ModalContent>
              {(group?.state === "PENDING" || group?.state === "CLOSED") && (
                <ModalButton onClick={handleActivate}>
                  {t("group.actionActivate")}
                </ModalButton>
              )}
              {(group?.state === "PENDING" || group?.state === "CLOSED") && (
                <ModalButton onClick={handleDelete}>
                  {t("group.actionDelete")}
                </ModalButton>
              )}
              <ModalButton onClick={handleClose}>
                {t("group.actionClose")}
              </ModalButton>
              <br />
              <CancelButton onClick={handleCloseAdminModal}>
                {t("order.cancel")}
              </CancelButton>
            </ModalContent>
          </Modal>
        </>
      )}
      {showContactsModal && group && (
        <>
          <ModalOverlay />
          <ModalContact>
            <ModalContactContent>
              {group.contacts.length > 0 ? (
                group.contacts.map((contact) => (
                  <ContactTableRow key={contact.id}>
                    <ContactTableCell width="20%">
                      {contact.contactType === "PHONE" ? (
                        <FaWhatsapp size={20} color="#25D366" />
                      ) : (
                        <FaUsers size={20} color="#25D366" />
                      )}
                    </ContactTableCell>
                    <ContactTableCell width="90%">
                      <a
                        href={
                          contact.contactType === "PHONE"
                            ? `https://wa.me/${contact.contact}`
                            : contact.contact
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {contact.name}
                      </a>
                    </ContactTableCell>
                  </ContactTableRow>
                ))
              ) : (
                <span>{t("group.emptyContacts")}</span>
              )}
              <br />
              <CancelButton onClick={handleCloseContactsModal}>
                {t("order.cancel")}
              </CancelButton>
            </ModalContactContent>
          </ModalContact>
        </>
      )}
      {showShareModal && (
        <>
          <ModalOverlay />
          <Modal>
            <ModalContent>
              <ShareLinkContainer>
                <ShareLinkInput value={shareLink} readOnly />
                <CopyIcon onClick={handleCopyLink} />
                <WhatsappButton
                  href={`https://wa.me/?text=Entre%20no%20nosso%20grupo%20atrav%C3%A9s%20do%20link:%20${encodeURIComponent(
                    shareLink
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp />
                </WhatsappButton>
              </ShareLinkContainer>
              <br />
              <CancelButton onClick={handleCloseShareModal}>
                {t("order.cancel")}
              </CancelButton>
            </ModalContent>
          </Modal>
        </>
      )}
      <NavBar />
    </Container>
  );
};

export default GroupPage;
