// src/pages/CreateDrawPage.tsx
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { createDraw } from "../services/api";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, ErrorMessage } from "../styles/CreateDrawStyles";
import { Container, BackButton } from "../styles/GlobalStyles";
import { DrawDTO } from "../types";
import { NumericFormat } from "react-number-format";

const CreateDrawPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Group ID
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [valuePerTicket, setValuePerTicket] = useState("");
  const [percentHome, setPercentHome] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (user && id) {
        const newDraw: Partial<DrawDTO> = {
          groupId: Number(id),
          drawTypeId: 1,
          valuePerTicket: Math.round(parseFloat(valuePerTicket) * 100) / 100,
          percentHome: Math.round((parseFloat(percentHome) / 100) * 100) / 100,
        };

        const createdDraw = await createDraw(newDraw);
        navigate(`/group/${id}`);
      }
    } catch (error) {
      setError(t("draw.createError"));
    }
  };

  return (
    <Container>
      <BackButton onClick={() => navigate("/group/" + id)}>{"<"}</BackButton>
      <Form onSubmit={handleSubmit}>
        <h2>{t("draw.createDraw")}</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <NumericFormat
          value={valuePerTicket}
          thousandSeparator
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          placeholder={t("draw.valuePerTicket")}
          onValueChange={(values) => setValuePerTicket(values.value)}
          customInput={Input}
        />
        <NumericFormat
          value={percentHome}
          suffix="%"
          decimalScale={0}
          allowNegative={false}
          placeholder={t("draw.percentHome")}
          onValueChange={(values) => setPercentHome(values.value)}
          customInput={Input}
        />
        <Button type="submit">{t("draw.createButton")}</Button>
      </Form>
    </Container>
  );
};

export default CreateDrawPage;
