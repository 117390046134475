import React, { useState } from "react";
import {
  ContactListContainer,
  ContactTable,
  ContactTableRow,
  ContactTableCell,
  AddContactButton,
  RemoveContactButton,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalButtonContainer,
  ConfirmButton,
  Input,
  Select,
  PhoneContainer,
  CountryCode,
  RegionalCode,
  PhoneNumber,
  ErrorMessage,
} from "../styles/ContactStyles";
import { CancelButton } from "../styles/GlobalStyles";
import { ContactGroupDTO } from "../types";
import { useTranslation } from "react-i18next";
import { FaWhatsapp, FaUsers } from "react-icons/fa";

interface ContactListProps {
  contacts: ContactGroupDTO[];
  onAddContact: (contact: ContactGroupDTO) => void;
  onRemoveContact: (index: number) => void; // Usando índice em vez de id
}

const ContactList: React.FC<ContactListProps> = ({
  contacts,
  onAddContact,
  onRemoveContact,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [regionalCode, setRegionalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [newContact, setNewContact] = useState<Partial<ContactGroupDTO>>({
    contactType: "PHONE",
    contact: "",
    name: "",
  });
  const [error, setError] = useState<string>("");

  const handleAddContact = () => {
    if (
      newContact.contactType &&
      (newContact.contact || (phoneNumber && regionalCode)) &&
      newContact.name
    ) {
      if (
        newContact.contactType === "PHONE" ||
        (newContact.contactType === "GROUP" &&
          isValidURL(newContact.contact as string))
      ) {
        const updatedContact =
          newContact.contactType === "PHONE"
            ? {
                ...newContact,
                contact:
                  "+55" +
                  regionalCode.replace(/[()]/g, "") +
                  phoneNumber.replace(/-/g, ""),
              }
            : newContact;
        onAddContact(updatedContact as ContactGroupDTO);
        setShowModal(false);
        setNewContact({ contactType: "PHONE", contact: "", name: "" });
        setPhoneNumber("");
        setRegionalCode("");
        setError("");
      } else {
        setError(t("group.invalidLink"));
      }
    } else {
      setError(t("group.fillAllFields"));
    }
  };

  const handleOpenAddContact = () => {
    setNewContact({ contactType: "PHONE", contact: "", name: "" });
    setPhoneNumber("");
    setRegionalCode("");
    setShowModal(true);
  };

  const handleRegionalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    const formattedValue = value.replace(/(\d{2})/, "($1)"); // Add formatting
    setRegionalCode(formattedValue);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    const formattedValue = value.replace(/(\d{5})(\d{4})/, "$1-$2"); // Add formatting
    setPhoneNumber(formattedValue);
  };

  const isValidURL = (url: string) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocolo
        "((([a-zA-Z0-9$-_@.&+!*\"'(),]+\\.)+[a-zA-Z]{2,})|" + // domínio
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // ou endereço IP (v4)
        "(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*" + // porta e caminho
        "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" + // query string
        "(\\#[-a-zA-Z0-9_]*)?$", // fragmento
      "i"
    );
    return urlPattern.test(url);
  };

  return (
    <ContactListContainer>
      <AddContactButton type="button" onClick={handleOpenAddContact}>
        {t("group.addContact")}
      </AddContactButton>
      <ContactTable>
        {contacts.map((contact, index) => (
          <ContactTableRow key={index}>
            <ContactTableCell width="10%">
              {contact.contactType === "PHONE" ? (
                <FaWhatsapp size={20} color="green" />
              ) : (
                <FaUsers size={20} color="green" />
              )}
            </ContactTableCell>
            <ContactTableCell width="40%">{contact.name}</ContactTableCell>
            <ContactTableCell width="40%">{contact.contact}</ContactTableCell>
            <ContactTableCell width="10%">
              <RemoveContactButton
                type="button"
                onClick={() => onRemoveContact(index)} // Usando índice
              >
                x
              </RemoveContactButton>
            </ContactTableCell>
          </ContactTableRow>
        ))}
      </ContactTable>
      {showModal && (
        <>
          <ModalOverlay />
          <Modal>
            <ModalContent as="form" onSubmit={handleAddContact}>
              <Select
                value={newContact.contactType}
                onChange={(e) =>
                  setNewContact({ ...newContact, contactType: e.target.value })
                }
              >
                <option value="PHONE">{t("group.contactType.phone")}</option>
                <option value="GROUP">{t("group.contactType.group")}</option>
              </Select>
              <Input
                placeholder="Nome"
                value={newContact.name}
                onChange={(e) =>
                  setNewContact({ ...newContact, name: e.target.value })
                }
              />
              {newContact.contactType === "GROUP" && (
                <Input
                  placeholder={t("group.contactPlaceholder")}
                  value={newContact.contact}
                  onChange={(e) =>
                    setNewContact({ ...newContact, contact: e.target.value })
                  }
                />
              )}
              {newContact.contactType === "PHONE" && (
                <PhoneContainer>
                  <CountryCode>🇧🇷+55</CountryCode>
                  <RegionalCode
                    type="text"
                    placeholder={t("register.regionalCodePlaceholder")}
                    value={regionalCode}
                    onChange={handleRegionalCodeChange}
                    maxLength={4} // (xx)
                    required
                    autoComplete="tel-national"
                  />
                  <PhoneNumber
                    type="text"
                    placeholder={t("register.phoneNumberPlaceholder")}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={10} // xxxxx-xxxx
                    required
                    autoComplete="tel-national"
                  />
                </PhoneContainer>
              )}
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <ModalButtonContainer>
                <CancelButton type="button" onClick={() => setShowModal(false)}>
                  {t("order.cancel")}
                </CancelButton>
                <ConfirmButton
                  type="button"
                  onClick={handleAddContact}
                  disabled={
                    (newContact.contactType === "PHONE"
                      ? !regionalCode || !phoneNumber
                      : !newContact.contact) || !newContact.name
                  }
                >
                  {t("order.confirmTicket")}
                </ConfirmButton>
              </ModalButtonContainer>
            </ModalContent>
          </Modal>
        </>
      )}
    </ContactListContainer>
  );
};

export default ContactList;
