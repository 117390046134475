import styled from "styled-components";
import { FaCopy } from "react-icons/fa";

export const PixContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 400px;
`;

export const PixCode = styled.span`
  font-size: 16px;
  color: #333;
  margin-right: 10px;
`;

export const PixType = styled.span`
  font-size: 14px;
  color: #777;
  margin-right: 5px;
`;

export const CopyIcon = styled(FaCopy)`
  cursor: pointer;
  color: #6200ee;

  &:hover {
    color: #3700b3;
  }
`;
