import styled from "styled-components";

export const OrderCard = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: #f5f5f5;
  padding: 20px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-bottom: 40px;
`;

export const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const OrderPin = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
`;

export const OrderState = styled.span`
  background-color: #6200ee;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
`;

export const TicketCard = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const TicketUserName = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 10px;
  text-align: left;
`;

export const TicketNumberList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const TicketNumberBall = styled.div<{ isDrawn: boolean }>`
  width: 35px;
  height: 35px;
  margin: 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.isDrawn ? "#4caf50" : "#eee")};
  color: ${(props) => (props.isDrawn ? "#fff" : "#000")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ValueDetails = styled.div`
  display: flex;
  justify-content: right;
  font-size: 16px;
  font-weight: bold;
  color: #666;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const ModalButton = styled.button<{ isDanger?: boolean }>`
  padding: 10px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #3700b3;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

export const Button = styled.button`
  padding: 10px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #3700b3;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

export const UserName = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: left;
`;

export const PIN = styled.div`
  font-size: 14px;
  text-align: left;
`;

export const ContactManagerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const ContactManagerLink = styled.a`
  display: flex;
  align-items: center;
  color: #25d366; // WhatsApp green color
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  & > svg {
    margin-right: 5px;
  }
`;
