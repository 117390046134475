import React, { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import {
  NotificationCard,
  NotificationContent,
  ClearButton,
  TitleContainer,
} from "../styles/NotificationsStyles";
import NavBar from "../components/NavBar";
import { Container, BackButton, PageTitle } from "../styles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const { user, notifications, resetUnreadNotifications, clearNotifications } =
    useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      resetUnreadNotifications();
    }
  }, [user, resetUnreadNotifications]);

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)}>{"<"}</BackButton>
      <TitleContainer>
        <PageTitle>{t("notifications.title")}</PageTitle>
        <ClearButton onClick={clearNotifications} />
      </TitleContainer>
      {notifications
        .sort((a, b) => b.id - a.id)
        .map((notification) => (
          <NotificationCard key={notification.id}>
            <NotificationContent>{notification.content}</NotificationContent>
          </NotificationCard>
        ))}
      {notifications.length === 0 && <p>{t("notifications.empty")}</p>}
      <NavBar />
    </Container>
  );
};

export default Notifications;
