export interface PhoneDTO {
  countryCode: string;
  regionalCode: string;
  phoneNumber: string;
  principal: boolean;
}

export interface RoleDTO {
  id: number;
  name: string;
}

export interface UserDTO {
  id?: number;
  username: string;
  name: string;
  nickname: string;
  email: string;
  password: string;
  confirmPassword?: string;
  phones: PhoneDTO[];
  roles: RoleDTO[];
  token: string;
}

export interface GroupDTO {
  id: number;
  name: string;
  pin: number;
  isPrivate: boolean;
  password: string;
  image: string;
  draws: DrawDTO[];
  userGroups: UserGroupDTO[];
  numberOfUsers: number;
  idManager: number;
  promoters: number[];
  managerPhone: string;
  state: string;
  contacts: ContactGroupDTO[];
}

export interface UserGroupDTO {
  id: number;
  user: UserDTO;
  promoter: UserDTO;
  group: GroupDTO;
  role: RoleDTO;
  limit: number;
  pixCode: string;
  pixType: PixType;
}

export interface TicketDTO {
  id: number;
  numbers: NumberDTO[];
  drawId: number;
  userId: number;
  orderId: number;
}

export interface NumberDTO {
  id: number;
  number: number;
  isDrawn: boolean;
  resultId: number | null;
}

export interface DrawDTO {
  id: number;
  state: string;
  inProgressDate: string;
  prizedDate: string;
  groupId: number;
  tickets: TicketDTO[];
  drawTypeId: number;
  valuePerTicket: number;
  numberOfTickets: number;
  percentHome: number;
  numberOfUsers: number;
  managerId: number;
  name: string;
}

export interface TicketDTO {
  id: number;
  numbers: NumberDTO[];
  drawId: number;
  userId: number;
  userName: string;
  orderId: number;
  state: string;
}

export interface LotteryResultDTO {
  id: number;
  drawDate: string;
  numbers: number[];
  drawTypeId: number;
  resultId: number;
}

export interface OrderDTO {
  id: number;
  pin: string;
  user: UserDTO;
  promoter: UserDTO;
  draw: DrawDTO;
  tickets: TicketDTO[];
  state: string;
  total: number;
  promoterPhone: string;
  userPhone: string;
  promoterPixCode: string;
  promoterPixType: PixType;
}

export interface InviteDTO {
  token: string;
  groupId: number;
  expirationDate: string; // Use string for date to match ISO 8601 format from backend
}

export interface PromoterSummaryDTO {
  promoterName: string;
  valueTotal: number;
}

export interface DrawSummaryDTO {
  drawId: number;
  total: number;
  valueHome: number;
  state: string;
  promotersSummary: PromoterSummaryDTO[];
  name: string;
}

export interface GroupSummaryDTO {
  groupId: number;
  drawsSummary: DrawSummaryDTO[];
}

export interface NotificationMessageDTO {
  id: number;
  content: string;
  userId: number;
  read: boolean;
}

export interface ContactGroupDTO {
  id: number;
  groupId: number;
  contactType: string;
  name: string;
  contact: string;
}

export interface FinancialMovementDTO {
  id: number;
  user: UserDTO;
  direction: "IN" | "OUT";
  date: string;
  group: GroupDTO;
  amount: number;
}

export enum PixType {
  CPF = "CPF",
  PHONE = "PHONE",
}
