import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { createGroup, uploadImage, existGroupName } from "../services/api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GroupDTO } from "../types";
import {
  Button,
  ErrorMessage,
  Form,
  Input,
  UploadContainer,
  UploadIcon,
  UploadText,
  UploadedImage,
} from "../styles/CreateGroupStyles";
import { Container, BackButton } from "../styles/GlobalStyles";
import { FaUsers } from "react-icons/fa";
import LoadingOverlay from "../components/LoadingOverlay";

const CreateGroupPage: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState("");
  const [groupImage, setGroupImage] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (user && groupName) {
        setIsLoading(true);
        let imageUrl = "";
        if (groupImage) {
          const uploadResponse = await uploadImage(groupImage);
          imageUrl = uploadResponse.data;
        }
        const newGroup: Partial<GroupDTO> = {
          name: groupName,
          isPrivate: false,
          password: "",
          image: imageUrl,
        };
        const createdGroup = await createGroup(newGroup, Number(user.id));
        navigate(`/group/${createdGroup.id}`);
      }
    } catch (error) {
      setError(t("group.updateError"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadClick = () => {
    document.getElementById("groupImageInput")?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setIsLoading(true);
      try {
        const resizedImage = await resizeImage(file, 500, 500);
        setGroupImage(resizedImage);
      } catch (error) {
        console.error("Error resizing image:", error);
      } finally {
        setIsLoading(false); // Finaliza o estado de carregamento
      }
    }
  };

  const resizeImage = (
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = (height * maxWidth) / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = (width * maxHeight) / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx!.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(resizedFile);
          } else {
            reject(new Error("Canvas is empty"));
          }
        }, file.type);
      };

      img.onerror = (error) => reject(error);
    });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
    setNameError("");
  };

  const handleNameBlur = async () => {
    if (groupName) {
      try {
        const nameExists = await existGroupName(groupName);
        if (nameExists) {
          setNameError(t("group.nameTaken"));
        }
      } catch (err) {
        // handle error if necessary
      }
    }
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay />}
      <BackButton onClick={() => navigate("/home")}>{"<"}</BackButton>
      <Form onSubmit={handleSubmit}>
        <h2>{t("group.createGroup")}</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
        <UploadContainer onClick={handleUploadClick}>
          {groupImage ? (
            <UploadedImage src={URL.createObjectURL(groupImage)} alt="Group" />
          ) : (
            <UploadIcon>
              <FaUsers size={100} />
              <UploadText>{t("group.addPhoto")}</UploadText>
            </UploadIcon>
          )}
        </UploadContainer>
        <Input
          id="groupImageInput"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }} // Hide the default file input
        />
        <Input
          type="text"
          placeholder={t("group.groupName")}
          value={groupName}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
        />
        <Button type="submit">{t("group.createButton")}</Button>
      </Form>
    </Container>
  );
};

export default CreateGroupPage;
