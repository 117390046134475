import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import { FaArrowLeft } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { SlOptionsVertical } from "react-icons/sl";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding-bottom: 60px; /* Espaço suficiente para o NavBar */
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  html, body, #root {
    height: 100%;
  }
`;

export default GlobalStyles;

export const BackButton = styled.button`
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #6200ee;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;

  &:hover {
    color: #3700b3;
  }
`;

export const SettingsButton = styled(SlOptionsVertical)`
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #6200ee;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;

  &:hover {
    color: #3700b3;
  }
`;

export const BackIcon = styled(FaArrowLeft)`
  margin-right: 8px;
`;

// Export other global styles if needed
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-x: hidden;
`;

export const ContainerGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

export const ContainerGroupUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 60px; /* to avoid content being hidden behind the navbar */
`;

export const ContainerHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const CreateIcon = styled(FaPlusCircle)`
  color: #b2a8c1;
  font-size: 38px;
  margin-top: 10px;
  margin-bottom: 100px;
`;

export const PageTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  color: #333;
`;

export const PageSubtitle = styled.h2`
  font-size: 1.75rem;
  font-weight: normal;
  text-align: center;
  margin: 10px 0;
  color: #666;
`;

export const CancelButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #6200ee;
`;

export const FloatingButton = styled.button`
  position: fixed;
  bottom: 80px; /* Ajuste conforme necessário para garantir que esteja acima da NavBar */
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #6200ee; /* Cor neutra, ajuste conforme necessário */
  color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000; /* Certifique-se de que o botão fique acima da NavBar */

  &:hover {
    background-color: #3700b3; /* Cor de destaque para o hover */
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

export const InfoMessage = styled.p`
  color: blue;
  text-align: center;
  margin-bottom: 10px;
`;
