import styled from "styled-components";
import { FaCameraRotate } from "react-icons/fa6";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

export const InfoMessage = styled.p`
  color: blue;
  text-align: center;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  padding: 10px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #3700b3;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

export const UploadIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px dashed #ddd;
  background-color: #f5f5f5;
`;

export const UploadText = styled.p`
  margin-top: 10px;
  color: #6200ee;
  font-size: 16px;
`;

export const UploadedImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
`;

export const GroupImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px; /* Adiciona margem inferior para espaçamento */
  cursor: pointer;
  position: relative;
  width: 120px; /* Ajuste conforme necessário */
  height: 120px; /* Ajuste conforme necessário */
`;

export const EditIcon = styled(FaCameraRotate)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 5px;
  color: #6200ee;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
