import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import {
  FaHome,
  FaUsers,
  FaClipboardList,
  FaClipboardCheck,
  FaBell,
  FaBalanceScaleLeft,
} from "react-icons/fa";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { IoSettingsOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { useAuth } from "../contexts/AuthContext";
import { useGroup } from "../contexts/GroupContext";
import { DrawDTO, GroupDTO, UserGroupDTO } from "../types";
import Badge from "@mui/material/Badge";
import {
  getDrawById,
  getPromotersByGroupId,
  getGroupById,
  API_BASE_URL,
} from "../services/api";

const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const NavBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #6200ee;
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const NavItem = styled.div<{ active: boolean; delay: number }>`
  color: ${({ active }) => (active ? "#3700b3" : "white")};
  font-size: 24px;
  text-align: center;
  flex: 1;
  cursor: pointer;
  position: relative;
  animation: ${slideIn} 0.3s ease-out;
  animation-delay: ${({ delay }) => delay}s;
  animation-fill-mode: forwards;
  opacity: 0;
`;

const GroupIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const NavBar: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string>("home");
  const [promoters, setPromoters] = useState<number[]>([]);
  const [group, setGroup] = useState<GroupDTO>();
  const [draw, setDraw] = useState<DrawDTO>();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, drawId } = useParams<{ id?: string; drawId?: string }>();
  const { user, unreadNotifications } = useAuth();
  const {
    group: groupContext,
    setGroup: setGroupContext,
    clearGroup,
  } = useGroup(); // Import the clearGroup function from GroupContext

  const handleItemClick = useCallback(
    (item: string, path: string) => {
      setActiveItem(item);
      if (item === "home") {
        clearGroup(); // Clear the group context when clicking on home
      }
      navigate(path);
    },
    [navigate, clearGroup]
  );

  useEffect(() => {
    if (location.pathname === "/home") {
      setActiveItem("home");
    } else if (location.pathname.includes("/group/")) {
      if (location.pathname.includes("/users")) {
        setActiveItem("users");
      } else if (location.pathname.includes("/summary")) {
        setActiveItem("groupSummary");
      } else if (location.pathname.includes("/settings")) {
        setActiveItem("groupSettings");
      } else {
        setActiveItem("group");
      }
    } else if (location.pathname.includes("/draw/")) {
      if (location.pathname.includes("/orders/promoter")) {
        setActiveItem("promoterOrders");
      } else if (location.pathname.includes("/orders")) {
        setActiveItem("orders");
      } else {
        setActiveItem("draw");
      }
    } else if (location.pathname === "/profile") {
      setActiveItem("profile");
    } else if (location.pathname === "/notifications") {
      setActiveItem("notifications");
    }
  }, [location]);

  useEffect(() => {
    const fetchPromoters = async (groupId: number) => {
      try {
        const response = await getPromotersByGroupId(groupId);
        const validPromoterIds = response
          .map((userGroup: UserGroupDTO) => userGroup.user.id)
          .filter((id): id is number => id !== undefined);

        setPromoters(validPromoterIds);
      } catch (error) {
        console.error("Error fetching promoters:", error);
      }
    };
    const fetchGroup = async (groupId: number) => {
      try {
        const response = await getGroupById(groupId);
        setGroup(response);
        setGroupContext(response); // Set group context
        setPromoters(response.promoters);
      } catch (error) {
        console.error("Error fetching group:", error);
      }
    };

    const fetchDrawAndPromoters = async () => {
      if (drawId) {
        try {
          const drawResponse = await getDrawById(Number(drawId));
          setDraw(drawResponse);
          fetchPromoters(drawResponse.groupId);
        } catch (error) {
          console.error("Error fetching draw details:", error);
        }
      }
    };

    if (location.pathname.includes("/draw/")) {
      fetchDrawAndPromoters();
    }
    if (location.pathname.includes("/group/")) {
      fetchGroup(Number(id));
    }
  }, [drawId, location.pathname, setGroupContext]);

  const groupNavItems = useMemo(
    () => (
      <>
        {user &&
          user.id &&
          (user.id === 1 ||
            promoters.includes(user.id) ||
            group?.idManager === user.id) && (
            <NavItem
              active={activeItem === "users"}
              onClick={() => handleItemClick("users", `/group/${id}/users`)}
              delay={0.1}
            >
              <FaUsers />
            </NavItem>
          )}
        {user && user.id && (user.id === 1 || group?.idManager === user.id) && (
          <>
            <NavItem
              active={activeItem === "groupSummary"}
              onClick={() =>
                handleItemClick("groupSummary", `/group/${id}/summary`)
              }
              delay={0.2}
            >
              <LiaFileInvoiceDollarSolid />
            </NavItem>
            <NavItem
              active={activeItem === "groupBalance"}
              onClick={() =>
                handleItemClick("groupBalance", `/group/${id}/balance`)
              }
              delay={0.3}
            >
              <FaBalanceScaleLeft />
            </NavItem>
            <NavItem
              active={activeItem === "groupSettings"}
              onClick={() =>
                handleItemClick("groupSettings", `/group/${id}/settings`)
              }
              delay={0.3}
            >
              <IoSettingsOutline />
            </NavItem>
          </>
        )}
      </>
    ),
    [activeItem, handleItemClick, id, user, group]
  );

  const drawNavItems = useMemo(
    () => (
      <>
        <NavItem
          active={activeItem === "orders"}
          onClick={() => handleItemClick("orders", `/draw/${drawId}/orders`)}
          delay={0.1}
        >
          <FaClipboardList />
        </NavItem>
        {user &&
          user.id &&
          (promoters.includes(user.id) || draw?.managerId === user.id) && (
            <NavItem
              active={activeItem === "promoterOrders"}
              onClick={() =>
                handleItemClick(
                  "promoterOrders",
                  `/draw/${drawId}/orders/promoter`
                )
              }
              delay={0.2}
            >
              <FaClipboardCheck />
            </NavItem>
          )}
      </>
    ),
    [activeItem, handleItemClick, drawId, user, promoters, draw]
  );

  return (
    <NavBarContainer>
      <NavItem
        active={activeItem === "home"}
        onClick={() => handleItemClick("home", "/home")}
        delay={0}
      >
        <FaHome />
      </NavItem>
      {groupContext?.image && (
        <NavItem
          active={true} // You can adjust this as needed
          onClick={() => navigate(`/group/${groupContext.id}`)}
          delay={0.1}
        >
          <GroupIcon src={`${API_BASE_URL}${groupContext.image}`} alt="Group" />
        </NavItem>
      )}
      {location.pathname.includes("/group/") && id
        ? groupNavItems
        : drawId
        ? drawNavItems
        : null}
      <NavItem
        active={activeItem === "profile"}
        onClick={() => handleItemClick("profile", `/profile`)}
        delay={0.3}
      >
        <CgProfile />
      </NavItem>
      <NavItem
        active={activeItem === "notifications"}
        onClick={() => handleItemClick("notifications", `/notifications`)}
        delay={0.3}
      >
        <Badge
          badgeContent={unreadNotifications}
          color="error"
          invisible={unreadNotifications === 0}
        >
          <FaBell />
        </Badge>
      </NavItem>
    </NavBarContainer>
  );
};

export default React.memo(NavBar);
